import React, {Component} from "react";
import {connect} from "react-redux";
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch} from "react-router-dom";
import {LocaleProvider} from "antd";
import {IntlProvider} from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import Info from "../Info";
import FAQ from "../FAQ";
import Support from "../Support";
import Terms from "../Terms";

import {setInitUrl} from "appRedux/actions/Auth";
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import Forgot from "../Forgot";
import Sales from "../Sales";
import Professional from "../Professional";
import Education from "../Education";
import InfoProduct from "../InfoProduct";
import ClinicExperience from "../ClinicExperience";
import Construction from "../Construction";
import Articles from "../Articles";
import Articles2 from "../Articles2";
import Linea1 from "../Linea1";
import Linea2 from "../Linea2";
import Linea3 from "../Linea3";
import Linea4 from "../Linea4";
import Linea5 from "../Linea5";
import Caso1 from "../Caso1";
import Caso2 from "../Caso2";
import Caso3 from "../Caso3";
import Caso4 from "../Caso4";
import Tarjeton from "../Tarjeton";
import Gastrobalance from "../Gastrobalance";
import Infography from "../Infography";
import Programs from "../Programs";
import FreqQuestions from "../FreqQuestions";
import Atlas from "../Atlas";


const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
 /* <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;*/
<Route
    {...rest}
    render={props =>
      
        <Component {...props} />
       
        }
  />;

class App extends Component {

  

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  componentWillMount() {
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);

    if (params.has("theme")) {
      this.props.setThemeType(params.get('theme'));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get('nav-style'));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get('layout-type'));
    }
  }

  render() {
    const {match, location, layoutType, navStyle, locale, authUser, initURL} = this.props;
    if (location.pathname === '/profesional-salud' || location.pathname === '/experiencia-clinica' || location.pathname === '/informacion-producto' || location.pathname === '/educacion-medica' || location.pathname === '/gastrobalance/programas-alimenticios'
      || location.pathname === '/gastrobalance/infografias' || location.pathname === '/gastrobalance/preguntas-frecuentes' || location.pathname === '/gastrobalance/atlas'  ) {
   
      if (authUser == null) {
        return ( <Redirect to={'/signin'}/> );
      } 
    }
  
     
      //return ( <Redirect to={initURL}/> );
    
  
    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>

          <Switch>
            {/*<Route exact path='/user-auth/sign-in' component={SignIn}/>*/}
            <Route exact path='/' component={Info}/>
            {/*<Route exact path='/faq' component={FAQ}/>*/}
             {/*<Route exact path='/soporte' component={Support}/>*/}
             {/*<Route exact path='/terminos' component={Terms}/>*/}
            <Route exact path='/reset-password' component={Forgot}/>
            <Route exact path='/signin' component={SignIn}/>
            <Route exact path='/profesional-salud' component={Professional}/>
            <Route exact path='/educacion-medica' component={Education}/>
            <Route exact path='/informacion-producto' component={InfoProduct}/>
            <Route exact path='/articulo-1' component={Articles}/>
            <Route exact path='/tarjeton-benequer' component={Tarjeton}/>
            <Route exact path='/gastrobalance' component={Gastrobalance}/>
            <Route exact path='/gastrobalance/infografias' component={Infography}/>
            <Route exact path='/gastrobalance/programas-alimenticios' component={Programs}/>
            <Route exact path='/gastrobalance/preguntas-frecuentes' component={FreqQuestions}/>
            <Route exact path='/gastrobalance/atlas' component={Atlas}/>

            <Route exact path='/articulo-2' component={Articles2}/>
            <Route exact path='/linea-gastro-1' component={Linea1}/>
            <Route exact path='/linea-gastro-2' component={Linea2}/>
            <Route exact path='/linea-gastro-3' component={Linea3}/>
            <Route exact path='/linea-gastro-4' component={Linea4}/>
            <Route exact path='/linea-gastro-5' component={Linea5}/>
            <Route exact path='/caso-clinico-1' component={Caso1}/>
            <Route exact path='/caso-clinico-2' component={Caso2}/>
            <Route exact path='/caso-clinico-3' component={Caso3}/>
            <Route exact path='/caso-clinico-4' component={Caso4}/>

            <Route exact path='/experiencia-clinica' component={ClinicExperience}/>
            <Route exact path='/gastrobalance' component={Construction}/>

            <Route exact path='/puntosdeventa' component={Sales}/>
            <Route exact path='/signup' component={SignUp}/>
           {<RestrictedRoute path={`${match.url}`} authUser={authUser} component={Info}/>}
          </Switch>
        </IntlProvider>
      </LocaleProvider>
    )
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {locale, navStyle, layoutType} = settings;
  const {authUser, initURL} = auth;
  return {locale, navStyle, layoutType, authUser, initURL}
};
export default connect(mapStateToProps, {setInitUrl, setThemeType, onNavStyleChange, onLayoutTypeChange})(App);
