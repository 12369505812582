import React, {useRef, useEffect} from "react"

export default function AutoPlaySilentVideo(props) {
    const videoRef = useRef(undefined);
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })

    return (
        <video
        id={props.idName}
            
        className={props.className}
            ref={videoRef}
            loop
            autoPlay="true"
            preload="none"
            playsInline
            muted
            >
            <source src={props.video} type='video/mp4; codecs="hvc1"'/>

			<source src={props.videowebm} type="video/webm"/>
            <source src={props.videoogg} type="video/ogg"/>

        </video>
   
    );
}