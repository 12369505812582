import React from "react";
import {Col, Row, Tabs, Avatar} from "antd";
import Widget from "components/Widget";
import AboutItem from "./AboutItem";


const TabPane = Tabs.TabPane;

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
     

    };
    //this.handleChange=this.handleChange.bind(this);
  }

  render() {
    return (
      <Widget title="Mi Información" styleName="gx-card-tabs gx-card-tabs-right gx-card-profile">
        <Tabs defaultActiveKey="1">
          <TabPane tab="." key="1">
            <div className="gx-mb-2">
              <Col>
              <center key="2" xl={24} lg={24} md={24} sm={24} xs={24}>
              <Avatar shape="square" size={100} src={this.props.user.avatar}/><br></br><br></br>
                  <span>Nombre del médico:</span><br></br>
                  <h4>{this.props.user.name}&nbsp;{this.props.user.paternal_lastname}&nbsp;{this.props.user.maternal_lastname}</h4>
                  <br></br>
                  <span>Estado</span><br></br>
                  <h4>{this.props.user.state}</h4>
                  <br></br>
                  <span>Especialidad</span><br></br>
                  <h4>{this.props.user.specialty}</h4>
                  <br></br>
                  <span>Cédula profesional</span><br></br>
                  <h4>{this.props.user.license}</h4>
               
                  </center>
                  
                  
                
              </Col>
            </div>
          </TabPane>
         
          
        </Tabs>
      </Widget>
    );
  }
}
/*icon: 'birthday-new',
icon: 'graduation',
    icon: 'home',
    icon: 'family',*/

export default About;
