import React from 'react';
import { Button, Checkbox, Form, Icon, Input, message, Dropdown, Menu, Card, Row, Col, Layout, Drawer, Divider } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth, database } from '../firebase/firebase';
import { version } from "util/config";
import AutoPlaySilentVideo from './AutoPlaySilentVideo';
import Cookies from 'js-cookie';

import {
	userSignOut,
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import Topbar from "./Topbar/index";
import HorizontalDefault from "./Topbar/HorizontalDefault/index";

const FormItem = Form.Item;

class SignIn extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			pass: "",
			showFrame: false,
			loggedInVia: false,
			//loggedSites:0,

			showCatFrame: false,
			showMGFrame: false,
			showInFrame: false,
			showGasFrame: false,
			origins: {},
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.setState({ email: values.email, pass: values.password });
				this.props.showAuthLoader();
				this.props.userSignIn(values);
			}
		});
	};

	handleButtonClick(e) {
		message.info('Click on left button.');
		//console.log('click left button', e);
	}

	handleMenuClick(e) {
		message.info('Click on menu item.');
		//console.log('click', e);
	}
	isValidEmail(email) {
		if (email.includes("@") && email.includes(".")) {
			return true;
		} else {
			return false;
		}
		//return /\S+@\S+\.\S+/.test(email);
	}
	 
	componentDidUpdate() {
		console.log("UPDATE")
		if (this.props.showMessage) {
			setTimeout(() => {
				this.props.hideMessage();
			}, 100);
		}
		if (this.props.authUser !== null) {
			var _this = this;
			auth.onAuthStateChanged((user) => {
				//if (this.state.loggedInVia == false) {
					if (user) {
						console.log("USER EXISTE");
						Cookies.set('user_email',_this.state.email, { domain: '.gastroin.com' })
						Cookies.set('user_password',_this.state.pass, { domain: '.gastroin.com' })
						Cookies.set('logged_in', true, { domain: '.gastroin.com' })

						_this.props.history.push('/');
						/*if (_this.state.showCatFrame == false) {
						
							if(this.iOS){
								console.log("iOS");
							}else{
								console.log("NO iOS");
							}
							_this.setState({ showCatFrame: true });
							setTimeout(() => {
								_this.setState({ showMGFrame: true });
							}, 500)
							setTimeout(() => {
								_this.setState({ showInFrame: true });
							}, 1000)
							setTimeout(() => {
								_this.setState({ showGasFrame: true });
								setTimeout(() => {
									_this.props.history.push('/profesional-salud');
								 }, 4000);
							
							}, 1500)
							window.addEventListener('message', function (event) {
								console.log(event);
								if (event.data == "remote-login-success") {
									_this.state.origins[event.origin] = true;
									//_this.setState({loggedSites: _this.state.loggedSites + 1});
								}
								if (Object.keys(_this.state.origins).length == 4) {
									_this.props.history.push('/profesional-salud');
								}

							});*/
						
							
							//_this.setState({showFrame: true});
							/*setTimeout(() => {
								this.props.history.push('/');
							 }, 6000);*/
					//	}
					} else {
						console.log("USER NOT EXIST");

						localStorage.removeItem('user_id');
      					localStorage.removeItem('user_email');
      					localStorage.removeItem('user_password');
						Cookies.remove('user_email', { domain: '.gastroin.com' })
						Cookies.remove('user_password', { domain: '.gastroin.com' })
						Cookies.set('logged_in', false, { domain: '.gastroin.com' })
					}
				/*} else {
					window.parent.postMessage('remote-login-success', '*')
					console.log("GastroTour: SESIÓN INICIADA");

				}*/
			});
		} else {
			//console.log('AUTH USER IS NULL');
		}

	}

	componentDidMount() {
		console.log("MOUNT")
		var email = Cookies.get('user_email', { domain: '.gastroin.com' })
		var pass = Cookies.get('user_password', { domain: '.gastroin.com' })
		var logged = Cookies.get('logged_in', { domain: '.gastroin.com' })
		console.log(email)
		console.log(pass)
		console.log(logged)
		if(logged != undefined){
			if(logged+"" === "true") {
				if(email && pass){
					console.log("YES COOKIES")
				var values = { email: email, password: pass }
				this.setState({ email: email, pass: pass });
				this.props.showAuthLoader();
				this.props.userSignIn(values);
				}else{
					console.log("Empty credentials")
				}
			}else{
				console.log("NOCOOKIES")
			}
		}


		/*const query = new URLSearchParams(this.props.location.search);
		const givenEmail = query.get('passedemail')
		const givenPassword = query.get('passedpassword')

		console.log(givenEmail)
		console.log(givenPassword)
		var _this = this;
		if (givenEmail != undefined && givenPassword != undefined) {
			if (givenEmail != "" && givenPassword != "") {
				console.log("Innovacion: PARÁMETROS ACEPTADOS");
				localStorage.setItem('user_email', givenEmail);
				localStorage.setItem('user_password', givenPassword);

				if (this.isValidEmail(givenEmail)) {

					this.setState({ loggedInVia: true });
					console.log("Innovacion: INICIANDO SESIÓN");
					this.props.userSignIn({ email: givenEmail, password: givenPassword });

				}
			}
		}*/
	}


	render() {
		const { getFieldDecorator } = this.props.form;
		const { showMessage, loader, alertMessage } = this.props;
		return (

			<div >
				<img className="background-video" src={require('../assets/images/thumbnail.jpg')} alt="innovacion_chinoin" title="innovacion_chinoin" />

				<AutoPlaySilentVideo className="background-video" video={require("../assets/images/video_back.mp4")} videowebm={require("../assets/images/video_back.webm")} videoogg={require("../assets/images/video_back.ogg")} />

				<HorizontalDefault />

				<div class="menu-mobile">

<div>
	<Row span={24}>

		<Col span={1}></Col>
		<Col span={3}>
			<div onClick={() => this.setState({ visible: true })}><Icon type="menu" style={{ fontSize: '36px', color: '#fff' }} /> </div>
		</Col>
		<Col span={4}><a href="/"><img id="home-icon" src={require('../assets/images/home_icon.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></a></Col>
		<Col span={7}>
			<img src={require('../assets/images/logo-main-small.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
		</Col>
		<Col span={2}></Col>
		<Col span={6}>
			<img id="slight-top" src={require('../assets/images/norutec.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
		</Col>
		<Col span={1}></Col>

	</Row>

	<Drawer


		placement="right"
		closable={false}
		visible={this.state.visible}
	>
		<br></br>
		<Icon type="close" style={{ fontSize: '30px', color: '#fff', float: 'right' }} onClick={() => this.setState({ visible: false })} />
		<a href="/"><p class="drawer-item">ACERCA DE</p></a>
		<Divider />
		<a href="/profesional-salud"><p class="drawer-item">PROFESIONAL DE LA SALUD</p></a>
		<ul>
			<li><a href="/educacion-medica"><p class="drawer-subitem">EDUCACIÓN MÉDICA CONTINUA</p></a><Divider></Divider></li>

			<li><a href="/informacion-producto"><p class="drawer-subitem">INFORMACIÓN DE PRODUCTO</p></a><Divider></Divider></li>

			<li><a href="/experiencia-clinica"><p class="drawer-subitem">EXPERIENCIA CLÍNICA</p></a><Divider></Divider></li>
		</ul>
		<a href="/gastrobalance"><p class="drawer-item">GASTROBALANCE</p></a>
		<Divider />
		<a href="/#contacto"><p class="drawer-item">CONTACTO</p></a>
		<Divider />
	</Drawer>
</div>


</div>
				<div className="gx-app-login-wrap">
				

					<div className="gx-app-login-container">
				
						<div className="gx-app-login-main-content">
						
							<div className="gx-app-login-content">

								<center>
									<img alt="example" src={require('assets/images/logo-main.png')} />
									<p className='login-text'>Si usted se ha registrado anteriormente en alguna de nuestras plataformas, inicie sesión con el mismo correo electrónico y contraseña, de lo contrario, le invitamos a realizar su registro</p>

								</center>
								<Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
									<FormItem>
										{getFieldDecorator('email', {
											rules: [
												{
													required: true,
													type: 'email',
													message: 'Por favor introduce un correo electrónico válido'
												}
											]
										})(<Input placeholder="Correo electrónico" />)}
									</FormItem>
									<FormItem>
										{getFieldDecorator('password', {
											rules: [{ required: true, message: 'Por favor introduce tu contraseña' }]
										})(<Input type="password" placeholder="Contraseña" />)}
									</FormItem>


									<center >
										<Col >


											<Button id="sesion" type="dashed" className="gx-mb-0" htmlType="submit">
												<IntlMessages id="app.userAuth.signIn" />
											</Button>

											<br></br><br></br>
											<Link to="/signup">
												<span class="colored">o Regístrate</span>
											</Link>		<br></br><br></br>
											<Link className="gx-login-form-forgot" to="/reset-password">Olvidé mi contraseña</Link>

										</Col>
										<p className='login-text-bottom'><br></br>Productos Farmacéuticos Chinoin® le brinda la oportunidad de prescribir una amplia gama de medicamentos eficaces y seguros, elaborados con los más altos estándares de calidad.
<br></br><br></br>
La información contenida en esta sección es exclusiva para profesionales de la salud. Productos Farmacéuticos Chinoin® no se hace responsable por el uso inadecuado de la información contenida en esta sección.</p>

									</center>

								</Form>


							</div>

							{loader ? (
								<div className="gx-loader-view">
									<CircularProgress />
								</div>
							) : null}
							{showMessage ? message.error(alertMessage.toString()) : null}

						</div>

						<span id="version-code">
							{version}</span>
							<br></br><br></br><br></br><br></br><br></br><br></br><br></br>
					</div>


				</div>
				<a href="https://www.chinoin.com/" target="_blank"><img id="corner-logo" src={require('../assets/images/esquina.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></a>

			</div>
		);
	}
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
	const { loader, alertMessage, showMessage, authUser } = auth;
	return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
	userSignIn,
	userSignOut,	
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGoogleSignIn,
	userGithubSignIn,
	userTwitterSignIn
})(WrappedNormalLoginForm);
