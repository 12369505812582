import React from 'react';
import { Button, Checkbox, Form, Icon, Input, message, Dropdown, Menu, Card, Row, Col, Layout } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth, database } from '../firebase/firebase';

import {
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import Topbar from './Topbar/index';
import HorizontalDefault from './Topbar/HorizontalDefault/index';


class Terms extends React.Component {
	componentDidUpdate() {}

	render() {
		return (
			<div className="gx-app-login-wrap">
				<HorizontalDefault />
			{/*mobilenav*/} 
				<div className="gx-app-login-wrap" id="login-cont">
					<Card title="Términos y condiciones y Aviso de privacidad">
						<p>
						<br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
						<br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
						Aviso de privacidad.<br></br><br></br>

Mediante este aviso y en cumplimiento con la Ley Federal de Datos Personales en Posesión de Particulares, Intersistemas, S.A. de C.V.  con domicilio en Aguiar y Seijas No. 75, Lomas de Chapultepec, CP 11000 en México, D.F. , informa que lo siguiente respecto al uso y tratamiento de datos personales:

Recaudación y Uso de Datos Personales<br></br><br></br>

Los datos personales son datos proporcionados voluntariamente y pueden ser utilizados para identificar o contactar a una persona.<br></br><br></br>

Al momento de entregar su información en esta página de evaluación de un proyecto patrocina o por una compañía particular, Usted expresamente consiente en proporcionar a Intersistemas, S.A. De C.V. Y si fuera necesario a la compañía patrocinadora, sus datos personales.<br></br>

Asimismo, Usted acepta y reconoce que dichos datos personales podrán ser combinados con información generada o adquirida por Intersistemas, S.A. De C.V. para invitarle a diversas actividades relacionadas con la Educación Médica Continua y la Actualización Médica.

A continuación encontrará una descripción de los propósitos para los que los datos personales serán utilizados:<br></br><br></br>

¿Qué tipo de datos personales recabamos?<br></br><br></br>

• Al momento de entregar la información que incluye sus datos personales en esta página de evaluación, dependiendo del programa que este respondiendo, Intersistemas, S.A. De C.V.  obtiene información diversa, incluyendo pero sin limitarse a, nombre completo, domicilios, especialidad médica, dirección de correo electrónico, números telefónicos, fecha de nacimiento y cédula profesional. 

¿Cómo utilizamos sus datos personales?<br></br><br></br>

• La información que Usted acepta en entregar a Intersistemas, S.A. De C.V., nos permite además de concluir los procesos de evaluación y generación de constancias si así aplica, llevar a cabo distintos tipos de análisis internos como son análisis de comportamientos históricos de respuestas, resultados y tiempos dentro de la evaluación, análisis socio demográficos, estrategias de educación e intereses en cuanto a Educación Médica se refiere, así como invitar a la participación de de otros programas.
<br></br><br></br>
• También utilizamos sus datos personales para poder tener contacto con Usted y promover, publicitar y mejorar nuestros programas de Educación Médica Continua, así como para llevar a cabo diversos tipos de encuestas de satisfacción, campañas de validación de datos, actividades de servicio al cliente y publicidad. De vez en vez podremos utilizar sus datos personales para comunicaciones importantes como puede ser la entrada en vigor de disposiciones legales, cambios en nuestras proyectos  o cambios relacionados a las evaluaciones.
<br></br><br></br>
• Una vez concluida su evaluación, Intersistemas, S.A. De C.V.  retendrá sus datos personales en una base de datos por un periodo de diez años con fines publicitarios y de promoción de otros programas. En caso de que Usted desee ser borrado de nuestra base de datos en cualquier momento, por favor contacte directamente a Intersistemas, S.A. De C.V. Al área de tratamiento de datos personales, al teléfono 55202073  de lunes a viernes de 8:00 a 15:00 h. o envíe directamente un e-mail a intersistemas@intersistemas.com.mx
<br></br><br></br>
Recaudación y Uso de información no personal.<br></br><br></br>

También recaudamos información no personal, esto es, información que no se puede asociar a persona alguna. Dicha información la utilizamos para propósitos estadísticos y propósitos diversos con el fin de obtener un panorama del comportamiento de nuestros participantes, nuestra posición como empresa dedicada a la Educación Médica Continua y nuestra experiencia como empresa editorial.
<br></br><br></br>
En caso de que Intersistemas, S.A. De C.V. combine información no personal con datos personales, a la información resultante se le dará el tratamiento de datos personales y en virtud de ello, estarán protegidos por la Ley y por lo que establece este Aviso de Privacidad.<br></br><br></br>

Cookies y otras tecnologías<br></br><br></br>

La página de evaluación a la que usted ha accesado, así como algunos mensajes de correo electrónico podrán utilizar ""cookies"" y otras tecnologías que nos ayudan a entender mejor el comportamiento de nuestros participantes-usuarios y nos ayudan a determinar la efectividad de nuestra plataforma de evaluación en línea.
<br></br>
En la medida de que dicha información no pueda ser asociada a un individuo en particular, le daremos el trato de información no personal. Si Usted desea deshabilitar las ""cookies"" lo puede hacer directamente en su browser de Internet.
<br></br><br></br>
Divulgación de Datos Personales a Terceros<br></br><br></br>

Intersistemas, S.A. De C.V. contrata con socios de negocio y proveedores de servicios de herramientas de Internet como es el caso de la plataforma de evaluación www.evaluacionmedica.com.mx  para la resolución de las evaluaciones. Con dichos socios de negocio y proveedores. Usted acepta y reconoce que Intersistemas, S.A. De C.V. comparte una herramienta de los datos personales para cumplir con el fin en cuestión.
<br></br>
Estos socios de negocios, y terceros están obligados a mantener como confidenciales los datos personales bajo los contratos respectivos que Intersistemas, S.A. De C.V. ha celebrado con ellos. Sin embargo, Intersistemas, S.A. De C.V. será responsable de cualquier incumplimiento de estos socios.
Es probable que en algún momento Intersistemas, S.A. De C.V. se vea obligada a divulgar datos personales por virtud de una ley, reglamento o de una resolución judicial.
<br></br><br></br>
Protección de Datos Personales
<br></br><br></br>
Intersistemas, S.A. De C.V. ha adoptado los niveles de seguridad de protección de los Datos Personales legalmente requeridos, y procura instalar aquellos otros medios y medidas técnicas adicionales a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los Datos Personales facilitados.
Acceso a sus Datos Personales
<br></br><br></br>
Usted puede asegurarse de que sus datos personales en nuestra base de datos son los correctos. Para ello, le pedimos que por favor contacte directamente a Intersistemas, S.A. De C.V. al teléfono 55202073 de lunes a viernes de 8:00 a 15:00 h. donde le indicarán los pasos necesarios para verificar y en su caso, corregir sus datos personales.
Preguntas
<br></br><br></br>
En caso de cualquier duda o aclaración en relación con el presente Aviso de Privacidad o con la administración de sus datos personales, le agradeceremos que por favor contacte directamente a Intersistemas, S.A. De C.V. al teléfono 55202073 de lunes a viernes de 8:00 a 15:00 h. o por medio de un correo electrónico a intersistemas@intersistemas.com.mx
<br></br><br></br>
Intersistemas, S.A. De C.V. podrá actualizar este Aviso de Privacidad de tiempo en tiempo mismo que será posteado en nuestra página en internet www.intersistemas.com.mx para lo cual, conforme a lo que establece la Ley, Usted será oportunamente informado."					
					
					
					
					
					
						</p>
						
					</Card>
			
			</div>
			</div>
		);
	}
}

export default Terms;
