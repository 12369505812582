import React, { Component } from "react";
import UserProfile from './../Sidebar/UserProfile';
import { auth, database, functions } from 'firebase/firebase';
import { userSignOut } from "appRedux/actions/Auth";

import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";

import { version } from "util/config";


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class HorizontalNav extends Component {

  state = {
    searchText: '',
    user:{}
  };
  getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";

    }
  };

  getUserInformation(user) {
    var _this = this;
    database
        .collection('users')
        .where('email', '==', user.email)
        .get()
        .then(function (querySnapshot) {
            querySnapshot
                .forEach(function (doc) {
                    _this.setState({
                        user: doc.data(),
                        userId: doc.id
                    });
                    _this.getUserAccessSeries();
                });
        })
        .catch(function (error) {});
}

  componentDidMount() {
    var _this = this;

    auth.onAuthStateChanged((user) => {
        if (user) {
            _this.getUserInformation(user);
        }else{
          _this.setState({
            user: {}
          });
        }
    });
}

  render() {
    const { pathname, navStyle } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (
      <Menu
      disabledOverflow={true}
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        mode="horizontal">
        <Menu.Item className={this.getNavStyleSubMenuClass(navStyle)} >
          <a href="/"><img src={require('../../assets/images/logo-main-small.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></a>

        </Menu.Item>
        <Menu.Item className={this.getNavStyleSubMenuClass(navStyle)} >

          <a className="item-menu-custom" href="/#acerca" >ACERCA DE</a>
        </Menu.Item>


        <SubMenu key="sub1" title={<span><a class="item-menu-custom" href="profesional-salud">PROFESIONAL DE LA SALUD</a></span>}>
          <Menu.Item key="5"><a class="blue" href="educacion-medica">EDUCACIÓN MÉDICA CONTINUA</a></Menu.Item>
          <Menu.Item key="6"><a class="blue" href="informacion-producto">INFORMACIÓN DE PRODUCTO</a></Menu.Item>
          <Menu.Item key="7"><a class="blue" href="experiencia-clinica">EXPERIENCIA CLÍNICA</a></Menu.Item>
        </SubMenu>

        <Menu.Item className={this.getNavStyleSubMenuClass(navStyle)} >
          <a className="item-menu-custom" href="/gastrobalance" >GASTROBALANCE</a>
        </Menu.Item>
        <Menu.Item className={this.getNavStyleSubMenuClass(navStyle)} >
          <a className="item-menu-custom" href="/#contacto" >CONTACTO</a>
        </Menu.Item>
     
        {
         (this.state.user.email) ?  <Menu.Item className={this.getNavStyleSubMenuClass(navStyle)} >
          <a className="item-menu-custom" onClick={() => this.props.userSignOut()} >CERRAR SESIÓN</a>
        </Menu.Item>:null
        
        }
        <Menu.Item className={this.getNavStyleSubMenuClass(navStyle)} >
          <img id="navbar-norutec" src={require('../../assets/images/norutec.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />

        </Menu.Item>


      </Menu>


    );
  }
}

HorizontalNav.propTypes = {};
const mapStateToProps = ({ settings }) => {
  const { themeType, navStyle, pathname, locale } = settings;
  return { themeType, navStyle, pathname, locale }
};
export default connect(mapStateToProps, { userSignOut })(HorizontalNav);

