import React from 'react';
import { Button, Checkbox, Form, Icon, Input, message, Dropdown, Menu, Card, Row, Col, Layout, Divider, Drawer } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth, database } from '../firebase/firebase';
import AutoPlaySilentVideo from './AutoPlaySilentVideo';

import {
	hideMessage,
	userSignOut,
	showAuthLoader,
	hideAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignIn,
	showAuthMessage,
	userTwitterSignIn
} from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import Topbar from "./Topbar/index";

import HorizontalDefault from "./Topbar/HorizontalDefault/index";
import {version} from "util/config";

const FormItem = Form.Item;

class Forgot extends React.Component {
	
	handleSubmit = (e) => {
		e.preventDefault();
		var _this = this;
		this.props.form.validateFields((err, values) => {
			if (!err) {
				//console.log('no error');

				//this.props.showAuthLoader();
		
				//auth.languageCode = 'es';
			
				auth.languageCode = 'es'
				auth.sendPasswordResetEmail(values.email).then(function(user) {
					//_this.props.hideAuthLoader()
					//console.log('sent');

					message.info("¡Correo enviado!");
					
				  }).catch(function(error) {
					  _this.props.hideAuthLoader()
					  //console.log('click left button', error);
					message.error("Ocurrió un error al enviar el correo, asegúrese de que la cuenta este registrada.") 
				  });
			}else{
				//console.log("error")
				//console.log(err);

			}
		});
	};

	handleButtonClick(e) {
		message.info('Click on left button.');
		//console.log('click left button', e);
	}

	handleMenuClick(e) {
		message.info('Click on menu item.');
		//console.log('click', e);
	}

	state = {
		searchText: '',
		user: {},
		visible: false,
	  };
	
	
	  getUserInformation(user) {
		var _this = this;
		database
		  .collection('users')
		  .where('email', '==', user.email)
		  .get()
		  .then(function (querySnapshot) {
			querySnapshot
			  .forEach(function (doc) {
				_this.setState({
				  user: doc.data(),
				  userId: doc.id
				});
				_this.getUserAccessSeries();
			  });
		  })
		  .catch(function (error) { });
	  }
	
	  componentDidMount() {
		var _this = this;
	
		auth.onAuthStateChanged((user) => {
		  if (user) {
			_this.getUserInformation(user);
		  } else {
			_this.setState({
			  user: {}
			});
		  }
		});
	  }
	

	render() {
		const { getFieldDecorator } = this.props.form;
		const { showMessage, loader, alertMessage } = this.props;

		return (
			<div >
				<img className="background-video" src={require('../assets/images/thumbnail.jpg')} alt="innovacion_chinoin" title="innovacion_chinoin" />

				<AutoPlaySilentVideo className="background-video" video={require("../assets/images/video_back.mp4")} videowebm={require("../assets/images/video_back.webm")} videoogg={require("../assets/images/video_back.ogg")} />

				<HorizontalDefault />

				<div class="menu-mobile">
<div>
<Row span={24}>

		<Col span={1}></Col>
		<Col span={3}>
			<div onClick={() => this.setState({ visible: true })}><Icon type="menu" style={{ fontSize: '36px', color: '#fff' }} /> </div>
		</Col>
		<Col span={4}><a href="/"><img id="home-icon" src={require('../assets/images/home_icon.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></a></Col>
		<Col span={7}>
			<img src={require('../assets/images/logo-main-small.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
		</Col>
		<Col span={2}></Col>
		<Col span={6}>
			<img id="slight-top" src={require('../assets/images/norutec.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
		</Col>
		<Col span={1}></Col>

	</Row>

	<Drawer


		placement="right"
		closable={false}
		visible={this.state.visible}
	>
		<br></br>
		<Icon type="close" style={{ fontSize: '30px', color: '#fff', float: 'right' }} onClick={() => this.setState({ visible: false })} />
		<a href="/"><p class="drawer-item">ACERCA DE</p></a>
		<Divider />
		<a href="/profesional-salud"><p class="drawer-item">PROFESIONAL DE LA SALUD</p></a>
		<ul>
			<li><a href="/educacion-medica"><p class="drawer-subitem">EDUCACIÓN MÉDICA CONTINUA</p></a><Divider></Divider></li>

			<li><a href="/informacion-producto"><p class="drawer-subitem">INFORMACIÓN DE PRODUCTO</p></a><Divider></Divider></li>

			<li><a href="/experiencia-clinica"><p class="drawer-subitem">EXPERIENCIA CLÍNICA</p></a><Divider></Divider></li>
		</ul>
		<a href="/gastrobalance"><p class="drawer-item">GASTROBALANCE</p></a>
		<Divider />
		<a href="/#contacto"><p class="drawer-item">CONTACTO</p></a>
		<Divider />
	</Drawer>
</div>


</div>
				<div className="gx-app-login-wrap">
				

					<div className="gx-app-login-container">
				
						<div className="gx-app-login-main-content">
						
							<div className="gx-app-login-content">
							
						<Row><Col span="2"></Col><Col span="20">	<img alt="example" src={require('assets/images/logo-main.png')} /></Col><Col span="0"></Col>
					
						</Row>
						<br></br>	
							<Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
								<FormItem>
									{getFieldDecorator('email', {
										rules: [
											{
												required: true,
												type: 'email',
												message: 'Por favor introduce un correo electrónico válido'
											}
										]
									})(<Input placeholder="Email" />)}
								</FormItem>
														

								<FormItem>
									<Button type="primary" className="gx-mb-0" htmlType="submit">
										<IntlMessages id="Enviar link a correo electrónico" />
									</Button>
									
								</FormItem>
							</Form>

						</div>

						{loader ? (
							<div className="gx-loader-view">
								<CircularProgress />
							</div>
						) : null}
						{showMessage ? message.error(alertMessage.toString()) : null}
					</div>
				</div>
			</div>	
		</div>
		);
	}
}

const WrappedNormalForgetForm = Form.create()(Forgot);

const mapStateToProps = ({ auth }) => {
	const { loader, alertMessage, showMessage, authUser } = auth;
	return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
	userSignIn,
	userSignOut,
	hideMessage,
	showAuthLoader,
	hideAuthLoader,
	showAuthMessage,
	userFacebookSignIn,
	userGoogleSignIn,
	userGithubSignIn,
	userTwitterSignIn
})(WrappedNormalForgetForm);
