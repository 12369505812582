import React from 'react';
import { Button, Checkbox, Form, Icon, Spin, Input, message, Dropdown, Menu, Card, Row, Col, Layout, Select, Drawer, Divider } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth, database, functions } from '../firebase/firebase';
import { userSignOut } from "appRedux/actions/Auth";
import Moment from 'moment';
import localization from 'moment/locale/es'
import AutoPlaySilentVideo from './AutoPlaySilentVideo';

import {
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import Topbar from './Topbar/index';
import HorizontalDefault from './Topbar/HorizontalDefault/index';


const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class Professional extends React.Component {

	constructor() {
		super();
		this.state = {
			loading: false,
			subject: "Dudas sobre productos Chinoin®",
			message: '',
			showVisit: false,
			selectedVisit: '',
			visitStatus: "",
			userId: '',
			user: {},
			email: '',
			contactEmail: '',
			contactSubject: 'Dudas sobre productos Chinoin®',
			contactMessage: '',
			handleVisitLoader:false,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		//this.setRedirect = this.setRedirect.bind(this);
		this.handleVisits = this.handleVisits.bind(this);
		this.handleSubject = this.handleSubject.bind(this);
		this.handleEmail = this.handleEmail.bind(this);
		this.handleMessage = this.handleMessage.bind(this);


	}
	getInfo(user) {
		//console.log("ALL USER INFO CALLED");
		var _this = this;

		database
			.collection("users")
			.where("email", "==", user.email)
			.get()
			.then(function (querySnapshot) {

				querySnapshot.forEach(function (doc) {
					_this.setState({ user: doc.data(), userId: doc.id });
					console.log("ID: " + doc.id);
				});
			})
			.catch(function (error) {
				//console.log("Error getVideosInformation :", error);
			});
	}
	ref = player => {
		this.player = player
	}

	componentDidMount() {
		var _this = this;

		auth.onAuthStateChanged(user => {
			if (user) {
				_this.getInfo(user);
			}
		});
	}

	handleVisits() {
		console.log("handleVisits")
		this.setState({handleVisitLoader:true})
		var addMessage = functions.httpsCallable('medicalvisit');
		Moment().locale('es', localization)
		var dateMessage = Moment(new Date())
			.locale('es')
			.format('D [de] MMMM [del]  YYYY');
		var _this = this;
		addMessage({
			userId: this.state.userId,

			date: dateMessage,
			method: this.state.selectedVisit
		})
			.then(function (result) {
				console.log("sucess")
				_this.setState({ contactEmail: '', contactSubject: '', contactMessage: '' })

				// Read result of the Cloud Function.
				_this.setState({handleVisitLoader:false})

				_this.setState({ visitStatus: "success" })
				//var sanitizedMessage = result.data.text;
			})
			.catch(function (error) {

				_this.setState({ visitStatus: "error" })
				_this.setState({handleVisitLoader:false})

				// Getting the Error details.
				var code = error.code;
				var message = error.message;
				var details = error.details;

				console.log("Function error")
				console.log(code)
				console.log(message)
				//console.log(details)
				// ...
			});
	}
	sendContactForm() {
		console.log("handleVisits")

		var addMessage = functions.httpsCallable('medicalvisit');
		Moment().locale('es', localization)

		var dateMessage = Moment(new Date())
			.locale('es')
			.format('D [de] MMMM [del]  YYYY');
		var _this = this;
		addMessage({
			userId: this.state.userId,

			date: dateMessage,
			method: this.state.selectedVisit
		})
			.then(function (result) {
				console.log("sucess")

				// Read result of the Cloud Function.
				_this.setState({ visitStatus: "success" })
				//var sanitizedMessage = result.data.text;
			})
			.catch(function (error) {

				_this.setState({ visitStatus: "error" })

				// Getting the Error details.
				var code = error.code;
				var message = error.message;
				var details = error.details;

				console.log("Function error")
				console.log(code)
				console.log(message)
				//console.log(details)
				// ...
			});
	}
	
	handleSubmit = (e) => {
		e.preventDefault();
	
		if(this.state.contactEmail != "" && this.state.contactSubject != "" && this.state.contactMessage != ""){
			if(/\S+@\S+\.\S+/.test(this.state.contactEmail)){
			//	if(this.state.contactEmail.includes("@") &&this.state.contactEmail.includes(".")){

			var addMessage = functions.httpsCallable('contactform');

		var dateMessage = Moment(new Date())
			.locale('es')
			.format('D [de] MMMM [del]  YYYY');
		var _this = this;
		addMessage({
			email: this.state.contactEmail,
			subject: this.state.contactSubject,
			date: dateMessage,
			message: this.state.contactMessage
		})
			.then(function (result) {
				console.log("sucess")
				alert("Enviado!")
				_this.setState({ contactEmail: '', contactSubject: '', contactMessage: '' })
				// Read result of the Cloud Function.
				_this.setState({ visitStatus: "success" })
				//var sanitizedMessage = result.data.text;
			})
			.catch(function (error) {

				_this.setState({ visitStatus: "error" })

				// Getting the Error details.
				var code = error.code;
				var message = error.message;
				var details = error.details;

				console.log("Function error")
				console.log(code)
				console.log(message)
				//console.log(details)
				// ...
			});
		}else{
			alert("Correo electrónico inválido")
		}
		}else{
			alert("Por favor llene todos los campos")
		}

	};

	handleSubject(e) {
		console.log(e);
		this.setState({ contactSubject: e});
	}
	handleMessage(e) {
		console.log(e.target.value);
		this.setState({ contactMessage: e.target.value });
	}
	handleEmail(e) {
		console.log(e.target.value);
		this.setState({ contactEmail: e.target.value });
	}

	render() {
		
		return (
			<div className="scrollable">
				<img className="background-video" src={require('../assets/images/thumbnail.jpg')} alt="innovacion_chinoin" title="innovacion_chinoin" />

				<AutoPlaySilentVideo className="background-video" video={require("../assets/images/video_back.mp4")} videowebm={require("../assets/images/video_back.webm")} videoogg={require("../assets/images/video_back.ogg")} />
				<HorizontalDefault />
				
				<div class="menu-mobile">

<div>
	<Row span={24}>

		<Col span={1}></Col>
		<Col span={3}>
			<div onClick={() => this.setState({ visible: true })}><Icon type="menu" style={{ fontSize: '36px', color: '#fff' }} /> </div>
		</Col>
		<Col span={4}><a href="/"><img id="home-icon" src={require('../assets/images/home_icon.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></a></Col>
		<Col span={7}>
			<img src={require('../assets/images/logo-main-small.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
		</Col>
		<Col span={2}></Col>
		<Col span={6}>
			<img id="slight-top" src={require('../assets/images/norutec.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
		</Col>
		<Col span={1}></Col>

	</Row>

	<Drawer


		placement="right"
		closable={false}
		visible={this.state.visible}
	>
		<br></br>
		<Icon type="close" style={{ fontSize: '30px', color: '#fff', float: 'right' }} onClick={() => this.setState({ visible: false })} />
		<a href="/"><p class="drawer-item">ACERCA DE</p></a>
		<Divider />
		<a href="/profesional-salud"><p class="drawer-item">PROFESIONAL DE LA SALUD</p></a>
		<ul>
			<li><a href="/educacion-medica"><p class="drawer-subitem">EDUCACIÓN MÉDICA CONTINUA</p></a><Divider></Divider></li>

			<li><a href="/informacion-producto"><p class="drawer-subitem">INFORMACIÓN DE PRODUCTO</p></a><Divider></Divider></li>

			<li><a href="/experiencia-clinica"><p class="drawer-subitem">EXPERIENCIA CLÍNICA</p></a><Divider></Divider></li>
		</ul>
		<a href="/gastrobalance"><p class="drawer-item">GASTROBALANCE</p></a>
		<Divider />
		<a href="/#contacto"><p class="drawer-item">CONTACTO</p></a>
		<Divider />
		{(this.state.user != {} && this.state.user != null) ? <a onClick={() => {auth.signOut();}}><p class="drawer-item">CERRAR SESIÓN</p></a> : null}
	</Drawer>
</div>


</div>
				{/*AQUI*/}
				<div className="">
					<div class="mobile-spacer">
						<br></br>	<br></br>	<br></br>	<br></br>	<br></br>
					</div>
					<Row>
						<Col span={24}>

							<Row span={24}>
							<Col lg={9} md={9} sm={2} xs={2}></Col>
								<Col lg={6} md={6} sm={20} xs={20}>
									<div className='base-cont'>
										<video id="logo-video-effect" autoPlay loop muted>
										<source src={require("../assets/images/sparkle.mp4")} type='video/mp4;codecs=hvc1' />

											<source src={require("../assets/images/sparkle.webm")} type='video/webm' />
										</video>
										<img class="base-logo" src={require('../assets/images/logo-main-fused.png')} />


									</div>
								</Col>
								<Col lg={9} md={9} sm={0} xs={0}></Col>

							</Row>
							<br></br><br></br>
							<Row>
								<Col lg={0} md={0} sm={2} xs={2}></Col>

								<Col lg={24} md={24} sm={20} xs={20}>
									{
										this.state.user.name != undefined ?<h1 className="h1-title">
										{
										
												(this.state.user.sex == "Femenino")?
												"Bienvenida " + this.state.user.name +" "+ this.state.user.paternal_lastname +" "+ this.state.user.maternal_lastname + "" :"Bienvenido " + this.state.user.name +" "+ this.state.user.paternal_lastname +" "+ this.state.user.maternal_lastname + ""
										
										
											
										}
										</h1> : <h1 className="h1-title">&nsbp;</h1>
									}
								</Col>
							</Row>
							<br></br>
							{/* Inicio de la sección de botones*/}
							<Row>
								<Col lg={6} md={6} sm={2} xs={2}></Col>
								<Col lg={13} md={13} sm={20} xs={20}>
									<div className='rcorners2'>
										<Row>
											<Col lg={2} md={2} sm={5} xs={5}><img class="home-icon" src={require('../assets/images/solicite.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
											<Col lg={17} md={17} sm={12} xs={12}><div class='home-text-cont'><span class="home-text-small">Solicite visita médica</span></div></Col>
											<Col lg={4} md={4} sm={6} xs={6}><div class="home-button-gray" onClick={() => this.setState({ showVisit: true })}><span>Solicitud</span></div></Col>
										</Row>
									</div>
								</Col>

								<Col lg={5} md={5} sm={2} xs={2}></Col>
							</Row>
							<br></br>
							<Row>
								<Col lg={6} md={6} sm={2} xs={2}></Col>
								<Col lg={6} md={6} sm={20} xs={20}>
									<div className='rcorners2'>
										<Row>
											<Col span={5}><img class="home-icon" src={require('../assets/images/cat.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
											<Col span={11}><span class="home-text-small">Educación Médica Continua</span></Col>
											<Col span={7}><div class="home-button-gray"><a href="/educacion-medica"><span>Programas</span></a></div></Col>
										</Row>
									</div>
								</Col>
								<Col lg={1} md={1} sm={3} xs={3}></Col>
								<Col lg={6} md={6} sm={20} xs={20} class="margin-sides">
									<div className='rcorners2 custom-margin'>
										<Row>
											<Col span={5}><img class="home-icon" src={require('../assets/images/eventos.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
											<Col span={11}><span class="home-text-small">Próximos<br></br>Eventos 2024</span></Col>
											<Col span={7}><div class="home-button-gray"><a href="#eventos"><span>Ver</span></a></div></Col>
										</Row>
									</div>
								</Col>
								<Col lg={6} md={6} sm={1} xs={1}></Col>
							</Row>
							<br></br>

							<Row>
								<Col lg={6} md={6} sm={3} xs={3}></Col>
								<Col lg={6} md={6} sm={20} xs={20}>
									<div className='rcorners2 custom-margin'>
										<Row>
											<Col span={5}><img class="home-icon" src={require('../assets/images/shield.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
											<Col span={11}><span class="home-text-small">Información de<br></br> producto</span></Col>
											<Col span={7}><div class="home-button-gray"><a href="informacion-producto"><span>Información</span></a></div></Col>
										</Row>
									</div>
								</Col>
								<Col lg={1} md={1} sm={2} xs={2}></Col>
								<Col lg={6} md={6} sm={20} xs={20}>
									<div className='rcorners2 margin-top'>
										<Row>
											<Col span={5}><img class="home-icon" src={require('../assets/images/experiencia.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
											<Col span={10}><span class="home-text-small">Experiencia<br></br>clínica</span></Col>
											<Col span={8}><div class="home-button-gray"><a href="/experiencia-clinica" target="_blank"><span>Contenidos</span></a></div></Col>
										</Row>
									</div>
								</Col>
								<Col span={6}></Col>
							</Row>
							<br></br>
							<Row>
							<Col lg={3} md={3} sm={0} xs={0}></Col>

								<Col lg={19} md={19} sm={24} xs={24}>
									<h1 className="h1-title">NOVEDADES</h1>
								</Col>
							</Row>
							<Row>
								<Col lg={6} md={6} sm={3} xs={3}></Col>
								<Col lg={13} md={13} sm={19} xs={19}>
									<div className='rcorners2 mobile-offset'>
										<Row className='margin-left'>
											<Col span={1}></Col>
											<Col lg={5} md={5} sm={20} xs={20}>
												<img id="acercamiento" src={require('../assets/images/acercamiento.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /><br></br><br></br>
											</Col>
											<Col lg={0} md={0} sm={3} xs={3}></Col>

											<Col lg={13} md={13} sm={22} xs={22}>
												<span class="home-text-small ">Módulo 5 - Las ventajas de los IBP<br></br><span class="text-italics-smaller">Dr. José Luis Tamayo de la cuesta</span></span><br></br><p class="text-blue"><br></br>Disponible</p><br></br>
											</Col>
											<Col lg={0} md={0} sm={2} xs={2}></Col>

											<Col lg={4} md={4} sm={20} xs={20}>
												<div class="home-button-gray"><a href="https://www.chinoingastroinnovacion.com/acerca-acercamiento" target="_blank"><span>Entrar</span></a></div><br></br><br></br><br></br>
											</Col>
										</Row>
										<Row className='margin-left'>
											<Col span={1}></Col>
											<Col lg={5} md={5} sm={20} xs={20}>
												<img id="innovando" src={require('../assets/images/logoinova.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
											</Col>
											<Col lg={0} md={0} sm={3} xs={3}></Col>

											<Col lg={13} md={13} sm={22} xs={22}>

												<span class="home-text-small">Colección Innovando en Gastroenterología<br></br><span class="text-italics-smaller">Todos los módulos disponibles</span></span><br></br><p class="text-blue"><br></br>Disponible</p>
											</Col>
											<Col lg={0} md={0} sm={2} xs={2}></Col>

											<Col lg={4} md={4} sm={20} xs={20}>
												<div class="home-button-gray"><a href="https://www.chinoingastroinnovacion.com/acerca-coleccion" target="_blank"><span>Especialista</span></a></div>
												<div class="home-button-gray"><a href="https://www.innovagastrochinoin.com/" target="_blank"><span>Médico general</span></a></div><br></br>
											</Col>
										</Row>
									</div>
								</Col>

								<Col lg={5} md={5} sm={4} xs={4}></Col>
							</Row>

							<br></br>	<br></br><br></br><br></br><br></br>	<br></br><br></br><br></br>
							{/* Inicio de la sección de Paralaje*/}

							<Row>
								<Col span={24}>
									<div class="parallax3">
										<img class="home-icon-down" src={require('../assets/images/down_arrow.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
									</div>

								</Col>
							</Row>
							<br></br>	<br></br><br></br>	<br></br><br></br>	<br></br><br></br>
							{/* Inicio de la sección de texto*/}
							<section id="eventos">
								<br></br><br></br>
								<Row>
									<Col lg={3} md={3} sm={2} xs={2}></Col>

									<Col lg={10} md={10} sm={20} xs={20}>
										<ul>
											<li><h3 class="h3-title">GASTRO-IN</h3>
												<h2 className='h2-title smaller-mobile'>Próximos Eventos 2024</h2>

												<div className='rcorners2'>
													<Row>
														<Col span={6}><span class="super-date">FEB / MAR<br></br><span class="text-bold">29/1-2</span></span></Col>
														<Col span={14}><span class="highligh-text-biggish">Endoregia</span>
															<br></br><span class="text-italics">Asociación Mexicana de Endoscopia Gastrointestinal y Colegio de Profesionistas, A.C.</span><br></br>
															<span className='home-text-small'>Sede: Monterrey</span>
														</Col>
														<Col span={3}><img class="event-icon" src={require('../assets/images/ameg.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
													</Row>
												</div></li>
											<li>
												<br></br>
												<div className='rcorners2'>
													<Row>
														<Col span={6}><span class="super-date">MAR<br></br><span class="text-bold">1-2</span></span></Col>
														<Col span={14}><span class="highligh-text-biggish">NeuroGastro</span>
															<br></br><span class="text-italics">Asociación Mexicana de Neurogastroenterología y Motilidad, A.C.</span><br></br>
															<span className='home-text-small'>Sede: Ciudad de México</span>
														</Col>
														<Col span={3}><img class="event-icon" src={require('../assets/images/amnm.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
													</Row>
												</div>
											</li>
											<li>
												<br></br>
												<div className='rcorners2'>
													<Row>
														<Col span={6}><span class="super-date">MAR<br></br><span class="text-bold">8-9</span></span></Col>
														<Col span={14}><span class="highligh-text-biggish">Regional Sur</span>
															<br></br><span class="text-italics">Asociación Mexicana de Gastroenterología, A.C.</span><br></br><br></br>
															<span className='home-text-small'>Sede: Puebla</span>
														</Col>
														<Col span={3}><img class="event-icon" src={require('../assets/images/amg.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
													</Row>
												</div>
											</li>
											<li>
												<br></br>
												<div className='rcorners2'>
													<Row>
														<Col span={6}><span class="super-date">MAY<br></br><span class="text-bold">3-4</span></span></Col>
														<Col span={14}><span class="highligh-text-biggish">Regional Centro</span>
															<br></br><span class="text-italics">Asociación Mexicana de Gastroenterología, A.C.</span><br></br><br></br>
															<span className='home-text-small'>Sede: Guadalajara</span>
														</Col>
														<Col span={3}><img class="event-icon" src={require('../assets/images/amg.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
													</Row>
												</div>
											</li>
											<li>
												<br></br>
												<div className='rcorners2'>
													<Row>
														<Col span={6}><span class="super-date">JUN<br></br><span class="text-bold">13-15</span></span></Col>
														<Col span={14}><span class="highligh-text-biggish">Endotapatia</span>
															<br></br><span class="text-italics">Asociación Mexicana de Endoscopia Gastrointestinal y Colegio de Profesionistas, A.C.</span><br></br>
															<span className='home-text-small'>Sede: Guadalajara</span>
														</Col>
														<Col span={3}><img class="event-icon" src={require('../assets/images/ameg.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
													</Row>
												</div>
											</li>
											<li>
												<br></br>
												<div className='rcorners2'>
													<Row>
														<Col span={6}><span class="super-date">JUL<br></br><span class="text-bold">18-20</span></span></Col>
														<Col span={14}><span class="highligh-text-biggish">ECOS Endoscópicos Internacionales</span>
															<br></br><span class="text-italics">Asociación Mexicana de Endoscopia Gastrointestinal y Colegio de Profesionistas, A.C.</span><br></br><br></br>
															<span className='home-text-small'>Sede: Ciudad de México</span>
														</Col>
														<Col span={3}><img class="event-icon" src={require('../assets/images/ameg.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
													</Row>
												</div>
											</li>
											<li>
												<br></br>
												<div className='rcorners2'>
													<Row>
														<Col span={6}><span class="super-date">AGO<br></br><span class="text-bold">22-24</span></span></Col>
														<Col span={14}><span class="highligh-text-biggish">ECOS Internacionales</span>
															<br></br><span class="text-italics">Asociación Mexicana de Gastroenterología, A.C.</span><br></br><br></br>
															<span className='home-text-small'>Sede: Ciudad de México</span>
														</Col>
														<Col span={3}><img class="event-icon" src={require('../assets/images/amg.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
													</Row>
												</div>
											</li>
											<li>
												<br></br>
												<div className='rcorners2'>
													<Row>
														<Col span={6}><span class="super-date">SEP<br></br><span class="text-bold">12-16</span></span></Col>
														<Col span={14}><span class="highligh-text-biggish">Reunión Nacional</span>
															<br></br><span class="text-italics">Asociación Mexicana de Endoscopia Gastrointestinal y Colegio de Profesionistas, A.C.</span><br></br><br></br>
															<span className='home-text-small'>Sede: Acapulco</span>
														</Col>
														<Col span={3}><img class="event-icon" src={require('../assets/images/ameg.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
													</Row>
												</div>
											</li>
											<li>
												<br></br>
												<div className='rcorners2'>
													<Row>
														<Col span={6}><span class="super-date">SEP<br></br><span class="text-bold">20-21</span></span></Col>
														<Col span={14}><span class="highligh-text-biggish">Regional Norte</span>
															<br></br><span class="text-italics">Asociación Mexicana de Gastroenterología, A.C.</span><br></br><br></br>
															<span className='home-text-small'>Sede: Ensenada</span>
														</Col>
														<Col span={3}><img class="event-icon" src={require('../assets/images/amg.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
													</Row>
												</div>
											</li>
											<li>
												<br></br>
												<div className='rcorners2'>
													<Row>
														<Col span={6}><span class="super-date">NOV<br></br><span class="text-bold">15-19</span></span></Col>
														<Col span={14}><span class="highligh-text-biggish">Semana Nacional de Gastroenterología</span>
															<br></br><span class="text-italics">Asociación Mexicana de Gastroenterología, A.C.</span><br></br><br></br>
															<span className='home-text-small'>Sede: Mérida</span>
														</Col>
														<Col span={3}><img class="event-icon" src={require('../assets/images/amg.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
													</Row>
												</div>
											</li>
										</ul>

									</Col>

									<Col lg={3} md={3} sm={0} xs={0}></Col>
									<Col lg={6} md={6} sm={0} xs={0}>
										<div className='base-cont'>
											<video id="logo-video-effect-verical" autoPlay loop muted>
											<source src={require("../assets/images/sparkle.mp4")} type='video/mp4;codecs=hvc1' />

												<source src={require("../assets/images/sparkle.webm")} type='video/webm' />

											</video>

											<img class='walking' src={require('../assets/images/oldmen_walking.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />


										</div>

									</Col>

									<Col lg={2} md={2} sm={0} xs={0}></Col>
								</Row>
							</section>
							<br></br><br></br><br></br><br></br><br></br>

							{/* Inicio de la sección de paralaje*/}
							<Row>
								<Col span={24}>
									<div class="parallax4">
										<img class="home-icon-down" src={require('../assets/images/down_arrow.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />

									</div>
								</Col>
							</Row>

							<section id="contacto">
								<Row>
									<Col lg={3} md={3} sm={3} xs={3}></Col>


									<Col lg={8} md={8} sm={19} xs={19}>
										<br></br>	<br></br>
										<div class="desktop-spacer">
											<br></br>	<br></br>	<br></br>	<br></br>	<br></br>


										</div>
										<h3 class="h3-title">GASTRO-IN</h3>
										<h2 className='h2-title'>Contacto</h2>
										<p class="regular-text">Para cualquier aclaración relacionada con esta plataforma, utilice nuestro <span class="highligh-text">Formulario de Contacto</span></p>
									
												<Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">


													<span class="regular-text">Email*</span>

													<Input rows={3} placeholder="e.g email@ejemplo.com" onChange={this.handleEmail}
														value={this.state.contactEmail} />

													<br></br>	<br></br>
													<span class="regular-text">Asunto*</span>

													<Select
														name="subject"
														onChange={this.handleSubject}
														value={this.state.subject}
														placeholder="Asunto"
													>
														<Option value="Dudas sobre productos Chinoin®">Dudas sobre productos Chinoin®</Option>
														<Option value="Contacto Chinoin®">Contacto Chinoin®</Option>
														<Option value="Soporte Académico">Soporte Académico</Option>
														<Option value="Soporte Técnico">Soporte Técnico</Option>

													</Select>

													<br></br>	<br></br>
													<span class="regular-text">Mensaje*</span>
													<TextArea rows={4} placeholder="Mensaje" onChange={this.handleMessage}
														value={this.state.contactMessage} />
													<br></br>	<br></br>
													<FormItem>
														<p
															id='contact-button'
															type="dashed"
															className="home-button-gray centered-text margin-left"
															htmlType="submit"
															onClick={this.handleSubmit}
														>
															<span class="regular-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Enviar&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
														</p>


													</FormItem>

												</Form>
										
									</Col>
									<Col lg={4} md={4} sm={0} xs={0}></Col>
									<Col lg={7} md={7} sm={0} xs={0}>
										<br></br>	<br></br>	<br></br>	<br></br>	<br></br>
										<div className='base-cont'>
											<AutoPlaySilentVideo idName="logo-video-effect" videowebm={require("../assets/images/sparkle.webm")} video={require("../assets/images/sparkle.mov")} />


											<img class='walking' src={require('../assets/images/men_walking.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />


										</div>

									</Col>
									<Col lg={2} md={2} sm={2} xs={2}></Col>
								</Row>
							</section>
						</Col>
					</Row>
					<br></br><br></br>
					<div class="desktop-spacer">
					<br></br><br></br><br></br><br></br><br></br><br></br><br></br>
</div>
					<Row id="footer-gradient">
						<Col span={2}>

						</Col>
						<Col lg={4} md={4} sm={20} xs={20}>
							<ul className="list-no-bullet">

								<li>
									<br></br>
									<Row>
										<Col lg={0} md={0} sm={2} xs={2}></Col>

										
										<Col lg={18} md={18} sm={18} xs={18}>
										<span class="footer-small-text">Plataforma académica patrocinada por Productos Farmacéuticos Chinoin®<br></br><br></br><br></br></span>

											<span class="footer-small-text">Productos Farmacéuticos Chinoin®<br></br>

												Lago Tangañica #18<br></br>
												Colonia Granada, 11520<br></br>
												México, D.F.<br></br>
												(55) 5262 31 00<br></br>
												Fax: (55) 5531 43 49<br></br>
												ventas@chinoin.com</span>
										</Col>
									</Row>
								</li>
							</ul>
						</Col>
						<Col lg={4} md={4} sm={4} xs={4}></Col>

						<Col lg={4} md={4} sm={19} xs={19}>
							<ul id="social-ul" className='margin-left' >
								<li><img class="footer-logo-norutec" src={require('../assets/images/norutec_alone.png')} /></li>
								<br></br>	
								<li><img class="footer-logo-norutec" src={require('../assets/images/benequer.png')} /></li>

								<br></br>	
								<li><img class="footer-logo-brand" src={require('../assets/images/talnesis.png')} /></li>
								<br></br>	
								<li><img class="footer-logo-brand-gelan" src={require('../assets/images/gelan-small.webp')} /></li>
								<br></br>	<br></br>	
								<li><img class="footer-logo-brand-inter" src={require('../assets/images/logo-intersistemas.png')} />
									<br></br>
									<span id="dev-text"> Desarrollado por Intersistemas S.A de C.V</span>
								</li>
								<br></br>		<br></br>			<br></br>
							</ul>
						</Col>
						<Col lg={4} md={4} sm={4} xs={4}>

						</Col>
						<Col lg={4} md={4} sm={20} xs={20}>
							<span class="regular-text">Accesos rápidos</span>
							<ul className="list-no-bullet">
								<li><a href="/#acerca"> <span className='footer-links'>Acerca de</span></a></li>
								<li><a href="profesional-salud"> <span className='footer-links'>Acceso Profesional de la Salud</span></a></li>
								<li><a href="gastrobalance"> <span className='footer-links'>Gastrobalance</span></a></li>
								<li><a href="puntosdeventa"> <span className='footer-links'>Puntos de venta</span></a></li>
								<li><a href="https://www.chinoin.com/"> <span className='footer-links'>Sobre Chinoin</span></a></li>
							<li><a href="https://www.chinoingastroinnovacion.com/aviso" target="_blank"> <span className='footer-links'>Aviso de privacidad</span></a></li>
								<div className='mobile-spacer'>
								<br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
							</div>
								

							</ul>
						</Col>

					</Row>
					<a href="https://www.chinoin.com/" target="_blank"><img id="corner-logo" src={require('../assets/images/esquina.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></a>

					<div id="social">
						<ul id="social-ul">

								<li className='social-list'><a href="https://www.facebook.com/ChinoinProductosFarmaceuticos" target="_blank"><img class='social-icon' src={require('../assets/images/face.png')} alt="" title="" /></a></li>
							<li className='social-list'><a href="https://www.instagram.com/chinoinpf/" target="_blank"><img class='social-icon' src={require('../assets/images/insta.webp')} alt="" title="" /></a></li>
							<li className='social-list'><a href="https://www.linkedin.com/company/chinoinpf/" target="_blank"><img class='social-icon' src={require('../assets/images/in.png')} alt="" title="" /></a></li>
							<li className='social-list'><a href="https://www.tiktok.com/@chinoinpf?is_from_webapp=1&sender_device=pc" target="_blank"><img class='social-icon' src={require('../assets/images/tiktok.png')} alt="" title="" /></a></li>
							<li className='social-list'><a href="https://twitter.com/ChinoinPF" target="_blank"><img class='social-icon' src={require('../assets/images/twitter.png')} alt="" title="" /></a></li>
							<li className='social-list'><a href="https://www.youtube.com/channel/UC3SguU-Dc1L61rFbBm8Lbww" target="_blank"><img class='social-icon' src={require('../assets/images/youtube.png')} alt="" title="" /></a></li>


						</ul>

					</div>


				</div>
				<div class="visit-ribbon" >
					<Row onClick={() => this.setState({ showVisit: true })}>
						<Col span={4}><img class="event-icon-ribbon" src={require('../assets/images/solicite.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
					
						<Col span={20}><br></br><div class="ribbon"><span>Solicite Visita Médica</span></div></Col>
					</Row>
				</div>
				{this.state.showVisit ? <div class="videoView mobile-span">
					<div className="visitDiv">
						<img class="close" onClick={() => this.setState({ showVisit: false })} src={require('../assets/images/close.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />

						<div id="centered">
							<div className='rcorners2' >
								<br></br>
								<Row>
									<Col span={10}></Col>
									<Col span={12}>
										<span class="home-text-small">Gastro-In</span><br></br>
									</Col>
								</Row>
								<Row>
									<Col span={2}></Col>
									<Col span={20}>
										<span class="font-huge">Solicitud de Visita Médica</span><br></br><br></br>
										<span class="visit-text-blue">Nos podremos en contacto con usted en breve</span><br></br>
										<span class="visit-text">¿Cuál de los siguientes medios es el de su preferencia?</span><br></br>
										<Dropdown id="visitselect" overlayStyle={{ backgroundColor: "#000" }} overlay={<Menu>
											<Menu.Item onClick={() => this.setState({ selectedVisit: "Correo electrónico" })}>
												<p className="dark-span" >Correo Electrónico</p>
											</Menu.Item>
											<Menu.Item onClick={() => this.setState({ selectedVisit: "WhatsApp" })}>
												<p className="dark-span" >WhatsApp</p>

											</Menu.Item>
											<Menu.Item onClick={() => this.setState({ selectedVisit: "Correo Electrónico y WhatsApp" })}>
												<p className="dark-span">Correo electrónico y WhatsApp</p>

											</Menu.Item>
										</Menu>} placement="bottomCenter">
											<div class="home-button-gray"><p className='clear-span'>{this.state.selectedVisit == '' ? "Seleccione su opción  " : this.state.selectedVisit}&nbsp;&nbsp;<Icon type="caret-down" style={{ fontSize: '16px', color: '#FFF' }} theme="outlined" /> </p></div>
										</Dropdown>
										<span class="visit-text">De click en ACEPTAR para confirmar su Solicitud de Visita Médica</span><br></br>
											{this.state.handleVisitLoader?<Spin tip="Enviando..."/>:
											<div class="home-button" onClick={this.handleVisits}><span>Aceptar</span></div>}
									</Col>
								</Row>
								<Row>
									<Col span={6}></Col>

								</Row>
								<br></br>	<br></br>
								{this.state.visitStatus == "success" ? <span class="visit-text-blue">¡Su solicitud ha sido enviada!</span> : <div></div>}
								{this.state.visitStatus == "error" ? <span class="visit-text-blue">Se produjo un error</span> : <div></div>}

								<br></br>

							</div>
						</div>
					</div>
					

				</div> : <div></div>}
			
			</div>
		);
	}
}
const WrappedNormalForgetForm = Form.create()(Professional);

export default connect(null, { userSignOut })(Professional);
