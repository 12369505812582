import React, {Component} from 'react';
import {Col, Row, Card, Button, Icon} from 'antd';
import EvaluationPorcentage from 'components/eCommerce/EvaluationPorcentage';
import {auth, database} from '../../../firebase/firebase';
import {cold} from 'react-hot-loader';
import Moment from 'moment';
import {func} from 'prop-types';

class EvaluationsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            userId: 1,
            urlId: 0,
            series: {},
            accessed_serie: {},
            solvedEvaluationsLoaded: false,
            solvedEvaluations: [],
            evaluations: []
        };
    }

    getSeriesInformation() {
        var _this = this;
        database
            .doc('/series/' + _this.state.urlId)
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    _this.setState({
                        series: doc.data()
                    });
                    database
                        .collection('/evaluations')
                        .where('series', '==', _this.state.urlId)
                        .get()
                        .then(function (snapshot) {
                            var evs = [];

                            snapshot
                                .docs
                                .forEach(function (damn) {
                                    database
                                        .collection('/questions')
                                        .where('evaluations', '==', damn.id)
                                        .get()
                                        .then(function (fack) {
                                            damn.questions = fack.docs;
                                            damn.total = fack.docs.length;
                                            evs.push(damn);
                                            if (evs.length == snapshot.docs.length) {
                                                var sorted = evs.sort(function (a, b) {
                                                    if (a.data().order < b.data().order) {
                                                        return -1;
                                                    }
                                                    if (a.data().order > b.data().order) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                });
                                                _this.setState({evaluations: sorted});
                                                _this.getUserEvaluations();
                                            }
                                        });
                                });
                        });
                } else {}
            });
    }

    getUserEvaluations() {
        var _this = this;
        database
            .collection('users/' + _this.state.userId + '/accesed_series/' + _this.state.accessed_serie.id + '/solved_evaluations')
            .get()
            .then(function (snapshot) {
                if (snapshot.docs.length == 0) {
                    var added = [];
                    _this
                        .state
                        .evaluations
                        .forEach(function (doc) {

                            database
                                .collection('users/' + _this.state.userId + '/accesed_series/' + _this.state.accessed_serie.id + '/solved_evaluations')
                                .add({
                                    correct_answers: '0',
                                    wrong_answers: '0',
                                    timestamp: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                                    grade: '0',
                                    evaluation_id: doc.id,
                                    porcentage: '0',
                                    status: 'Por iniciar',
                                    attempt_number: '1',
                                    evaluation_name: doc
                                        .data()
                                        .title
                                })
                                .then(function (docum) {

                                    database
                                        .doc('users/' + _this.state.userId + '/accesed_series/' + _this.state.accessed_serie.id + '/solved_evaluations/' + docum.id)
                                        .get()
                                        .then(function (itemx) {
                                            added.push(itemx);
                                            if (added.length == _this.state.evaluations.length) {
                                                _this.setState({solvedEvaluations: added, solvedEvaluationsLoaded: true});
                                            }
                                            if (added.length == _this.state.evaluations.length - 1) {
                                                _this.setState({solvedEvaluations: added, solvedEvaluationsLoaded: true});
                                            }
                                        })
                                        .catch(function (er) {});
                                })
                                .catch(function (error) {
                                    window
                                        .location
                                        .reload();

                                });
                        });
                } else {

                    var finalArray = [];
                    _this
                        .state
                        .evaluations
                        .forEach(function (item) {

                            var filtered = snapshot
                                .docs
                                .filter((word) => word.data().evaluation_id == item.id);
                            if (filtered.length > 1) {

                                filtered.sort((a, b) => (a.data().attempt_number > b.data().attempt_number)
                                    ? 1
                                    : -1)
                                finalArray.push(filtered[filtered.length - 1]);
                            } else if (filtered.length == 1) {

                                finalArray.push(filtered[0]);
                            } else {

                                database
                                    .collection('users/' + _this.state.userId + '/accesed_series/' + _this.state.accessed_serie.id + '/solved_evaluations')
                                    .add({
                                        correct_answers: '0',
                                        wrong_answers: '0',
                                        timestamp: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                                        grade: '0',
                                        evaluation_id: item.id,
                                        porcentage: '0',
                                        status: 'Por iniciar',
                                        attempt_number: '1',
                                        evaluation_name: item
                                            .data()
                                            .title
                                    })
                                    .then(function (doc) {
                                        database
                                            .doc(doc.path)
                                            .get()
                                            .then(function (it) {
                                                finalArray.push(it);
                                            });
                                    })
                                    .catch(function (error) {});
                            }
                        });

                    _this.setState({solvedEvaluations: finalArray, solvedEvaluationsLoaded: true});
                }
            })
            .catch(function (error) {});
    }

    getUserAccessSeries() {
        var _this = this;
        database
            .collection('users/' + _this.state.userId + '/accesed_series')
            .where('series_id', '==', _this.state.urlId)
            .get()
            .then(function (snapshot) {
                if (snapshot.docs.length == 0) {} else {
                    _this.setState({accessed_serie: snapshot.docs[0]});
                    _this.getSeriesInformation();
                }
            });
    }

    getUserInformation(user) {
        var _this = this;
        database
            .collection('users')
            .where('email', '==', user.email)
            .get()
            .then(function (querySnapshot) {
                querySnapshot
                    .forEach(function (doc) {
                        _this.setState({
                            user: doc.data(),
                            userId: doc.id
                        });
                        _this.getUserAccessSeries();
                    });
            })
            .catch(function (error) {});
    }

    componentDidMount() {
        const {urlId} = this.props.match.params;
        var _this = this;

        _this.setState({urlId: urlId});
        auth.onAuthStateChanged((user) => {
            if (user) {
                _this.getUserInformation(user);
            }
        });
    }

    render() {
        return (
            <div>
         
                <Button type="primary" href="/profile">
                    <Icon type="left" size="small"/>Regresar</Button>
                  
                <Row>
                    <Col key="ss" span={16}>
                        <Card className={`gx-card-metrics`}>
                            <h1 className="gx-product-title">{this.state.series.sponsor}</h1>
                            <h4>{this.state.series.evaluation_description}</h4>
                            <p>{this.state.series.sponsor_message}</p>
                            <p>{this.state.series.disclamer}</p>
                        </Card>
                    </Col>
                    <Col key="1a" span={8}>
                        <Card className={`gx-card-metrics`}>
                            <img src={this.state.series.sponsor_logo}/>
                        </Card>
                    </Col>
                    {this.state.evaluations.length > 0 && this.state.solvedEvaluationsLoaded == true
                        ? (this.state.evaluations.map((ev, index) => (
                            <Col key={index} span={24}>
                                <EvaluationPorcentage
                                    key={ev.id}
                                    width={(window.innerHeight*0.6 )*1.83}
                                    series={this.state.series}
                                    seriesId={this.state.urlId}
                                    evid={ev.id}
                                    product={ev.data()}
                                    solvedEv={this
                                    .state
                                    .solvedEvaluations
                                    .filter((item) => item.data().evaluation_id == ev.id)}/>
                            </Col>
                        )))
                        : null}
                </Row>
                v1.8
            </div>
        );
    }
}

export default EvaluationsList;
