import React from 'react';
import { Button, Checkbox, Form, Icon, Input, message, Dropdown, Menu, Card, Row, Col, Layout } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth, database } from '../firebase/firebase';

import {
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import Topbar from './Topbar/index';
import HorizontalDefault from './Topbar/HorizontalDefault/index';


class FAQ extends React.Component {
	componentDidUpdate() {}

	render() {
		return (
			<div className="gx-app-login-wrap">
				<HorizontalDefault />
			{/*mobilenav*/} 
				<div className="gx-app-login-wrap" id="login-cont">

				<div className="gx-app-login-container" id="overflower">
					<div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-18 ant-col-xl-19 ant-col-xxl-20">
					
					<Card title="F.A.Q.">
					
						<Card type="inner" title="Aún no cuento con clave de acceso ni con el material de estudio, ¿cómo puedo inscribirme a la evaluación?" >
						Si se trata de un programa patrocinado, contacte al representante del laboratorio patrocinador o bien, envíe su solicitud al correo smora@intersistemas.com.mx. Para programas no patrocinados, ingrese a la página http://www.medikatalogo.com.mx/tienda/					
					
					
						</Card>
							
						<Card type="inner" title="¿Cómo obtengo el material de estudio que me hace falta?" >
						Si no ha recibido todos los fascículos o extravió alguno, puede consultarlo en formato PDF al ingresar a la evaluación.					
					
										
					
						</Card>
							
						<Card type="inner" title="¿Cuántas oportunidades tengo para aprobar el examen?" >
						Para cada módulo del programa cuenta con tres oportunidades. Al presionar “Calificar” estará utilizando una oportunidad, el número de oportunidades restantes aparecerá en la pantalla de resumen de la evaluación. Si ya contestó el cuestionario pero desea revisar sus respuestas, puede optar por “Guardar para después continuar”, de esta manera tiene la posibilidad de no consumir oportunidades e ingresar posteriormente para obtener su calificación.					
					
										
					
						</Card>
							
						<Card type="inner" title="¿Cómo obtengo el puntaje que se otorga por esta evaluación?" >
						"Una vez aprobados todos los cuestionarios de la evaluación, aparecerá en pantalla la referencia para imprimir su comprobante, el cual contiene un código aprobación único que respalda su validez. Para acreditar el puntaje correspondiente se procede según lo estipulado para cada evaluación en particular, en la pantalla principal de su evaluación se indica cuál de las siguientes alternativas aplica para el programa en cuestión:

Al realizar su trámite de recertificación ante el organismo certificador que corresponda, adjunte el comprobante impreso que obtuvo de la página de Educación Médica Continua - Curso de actualización.

Conserve el comprobante impreso y un representante del laboratorio patrocinador le hará entrega de su constancia de aprobación. La constancia deberá presentarla posteriormente al realizar su trámite de recertificación."					
					
										
					
						</Card>
						<Card type="inner" title="¿Cuántas oportunidades tengo para aprobar el examen?" >
						Al seleccionar la evaluación en la parte inferior aparece su fecha de vigencia, de igual forma se muestra en la pantalla de resumen del examen. En ocasiones se extiende la fecha límite para presentar el examen, de ser así, la nueva fecha aparecerá en la pantalla de resumen de la evaluación.					
					
										
										
					
						</Card>
							
						<Card type="inner" title="Para cualquier duda:" >
						Envíe un correo electrónico a smora@intersistemas.com.mx 
Teléfono: (55) 5520 2073 ext. 230
Horario de atención de 7:00-15:00 hrs.				
					
					
										
					
						</Card>
						
					</Card>
				</div>
				</div>
			</div>
			</div>
		);
	}
}

export default FAQ;
