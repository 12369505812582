import firebase from "firebase";

// Initialize Firebase
const config = {
  databaseURL: "https://innovacionchinoin.firebaseio.com",
  apiKey: "AIzaSyDSyyFWDfpmcdRe-o4AcuYkR4tbXwYHYX0",
  authDomain: "innovacionchinoin.firebaseapp.com",
  projectId: "innovacionchinoin",
  storageBucket: "innovacionchinoin.appspot.com",
  messagingSenderId: "391693231131",
  appId: "1:391693231131:web:341e54c51ff695230384be",
  measurementId: "G-17SNG17F6X"
};
firebase.initializeApp(config);
firebase.auth().languageCode = 'es'
const auth = firebase.auth();
const functions = firebase.functions();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const storage = firebase.storage();

const database = firebase.firestore();

export {
  database,
  storage,
  auth,
  functions,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
