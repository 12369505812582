import React from 'react';
import { Button, Checkbox, Form, Icon, Input, message, Dropdown, Menu, Card, Row, Col, Layout, Select, } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth, database } from '../firebase/firebase';
import { Redirect } from "react-router-dom";

import {
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import Topbar from './Topbar/index';
import HorizontalDefault from './Topbar/HorizontalDefault/index';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class Construction extends React.Component {

	constructor() {
		super();
		this.state = {
			loading: false,
			subject: "Dudas sobre productos Chinoin®",
			message: '',
			showVideo: false,
			videoId: '',
			videoTitle: '',
			videoCategory: '',
			email: '',
		};
		this.handleSubmit = this.handleSubmit.bind(this);

		//this.setRedirect = this.setRedirect.bind(this);
		//this.handleSignIn = this.handleSignIn.bind(this);
		this.handleSubject = this.handleSubject.bind(this);


	}
	handleSubmit = (e) => {
		e.preventDefault();
		//this.props.form.validateFields((err, values) => {
		//this.handleSignIn(err, values);
		//console.log(moment(values.birthdate).format('MM/DD/YYYY'));
		//});
	};
	handleSubject(e) {
		//console.log(e);
		console.log(e);
		//
		this.setState({ subject: e });
	}
	
	componentDidUpdate() { }

	render() {

		return (
			<div class="videoView">
				<div className="conChild">

					<div id="centered">
						<div className='rcorners2' >
						<Icon type="close" style={{ fontSize: '25px', color: '#fff' }}  onClick={()=>{this.setState({showVideo:true})}}/>
						{this.state.showVideo?<Redirect to="/" />:null}
							<br></br>
							<Row>
								
								<Col span={9}></Col>
								<Col span={6}>
									<span class="home-text-small">Gastro-In</span><br></br>
								</Col>
							</Row>
							<Row>
								<Col span={2}></Col>
								<Col span={20}>
									<span class="text-h3-cent">Estamos construyendo algo nuevo para ti</span><br></br><br></br>
								</Col>
							</Row>
							<Row>
								<Col span={10}></Col>
								<Col span={6}>
									<img class="event-icon-small-fixed" src={require('../assets/images/construyendo.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
								</Col>
							</Row>

							<br></br>

						</div>
					</div>
				</div>
			</div>


		);
	}
}

export default Construction;