import React, { Component } from 'react';
import { Col, Row, Card, Button, Icon, Progress } from 'antd';
import CaseItem from "components/eCommerce/CaseItem";
import { auth, database } from "../../../firebase/firebase";
import About from '../../../components/profile/About';
import AboutItem from '../../../components/profile/About/AboutItem';

class CasesGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      urlId: 0,
      series: {},
      cases: []
    };
    //this.handleChange=this.handleChange.bind(this);
  }

  calculatePorcentage() {
    var _this = this;
    var path = "users/" + _this.state.userId + '/read_cases';
    console.log("Checking if read");
    console.log(path);
    database
      .collection(path).where('series_id', '==', _this.state.urlId)
      .get()
      .then(function (querySnapshot) {
        //console.log('OPTIONS'); //console.log(querySnapshot);
        if (querySnapshot.docs.length != 0) {
          var readMapVar = {};
          querySnapshot.docs.forEach(element => readMapVar[element.data().case_id] = true);
          _this.setState({ readMap: readMapVar });
     

          var por = parseInt((querySnapshot.docs.length / _this.state.totalCases) * 100)
          _this.setState({ porcentage: por, readCasesLength: querySnapshot.docs.length,  cases:_this.state.tmpcases });
          console.log("length:" + querySnapshot.docs.length)
          console.log("total:" + _this.state.totalCases)

          console.log("porcentage:" + por)
          
        } else {
          _this.setState({ porcentage: 0, readCasesLength: 0, cases:_this.state.tmpcases });
          console.log("0% reare case")
        }
      }).catch(function (error) {
        _this.setState({ porcentage: 0, readCasesLength: 0,  cases:_this.state.tmpcases });
      });
  }

  getUserInformation(user) {
    var _this = this;
    database
      .collection("users")
      .where("email", "==", user.email)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          _this.setState({ user: doc.data(), userId: doc.id, readMap:{} });
          doc.ref
            .collection("accesed_series")
            .where("series_id", "==", _this.state.urlId)
            .get()
            .then(function (snapshot) {
              var refArray = [];
              snapshot.forEach(function (series) {
                refArray.push(series.data());
              });
              if (refArray.length == 0) {
              } else {
                database
                  .doc("series/" + _this.state.urlId)
                  .get()
                  .then(function (doc) {
                    if (doc.exists) {
                      var series = doc.data();
                      //console.log(series);
                      _this.setState({ series: series });
                      var query = database
                        .collection("clinic_cases")
                        .where("series", "==", doc.id)
                        query= query.where("active", "==", true)
                        query.get()
                        .then(function (snapshot) {
                          var arr = snapshot.docs.sort(function (a, b) {
                            if (a.data().title < b.data().title) { return -1; }
                            if (a.data().title > b.data().title) { return 1; }
                            return 0;
                          });
                          _this.setState({ tmpcases: arr, totalCases: arr.length });
                          _this.calculatePorcentage()

                        });
                    } else {
                    }
                  });
              }
            });
        });
      })
      .catch(function (error) {
      });
  }

  componentDidMount() {
    const { urlId } = this.props.match.params;
    var _this = this;

    this.setState({ urlId: urlId , loaded:false});
    auth.onAuthStateChanged(user => {
      if (user) {
        _this.getUserInformation(user);
      }
    });
  }

  render() {
    return (
      <div>
        <Row>
          <Col key="1a" span={4}>
            <Card className={`gx-card-metrics`}>
              <img src={"https://firebasestorage.googleapis.com/v0/b/innovacionchinoin.appspot.com/o/logo-left.png?alt=media&token=41843bdd-239a-4291-ae47-6837d00e8ba1"} />
            </Card>

        <About user={this.state.user} /><Card className={`gx-card-metrics`}>
              <h3>Avance</h3>
              <h4>Usted ha visualizado {this.state.readCasesLength} casos clínicos de un total de {this.totalCases}</h4>
              <Progress percent={this.state.porcentage} size="small" status="active" strokeColor="#EF55A8" />
              <span>Porcentage de avance</span>

            </Card>
          </Col>
          <Col key="aa" span={20}>
     
            <Card className={`gx-card-metrics`}
            >
              <Button type="primary" href="/profile">
                <Icon type="left" size="small" />Regresar</Button>
              <h1 className="gx-product-title">{this.state.series.title}</h1>
              <h4>{this.state.series.cases_description}</h4>
              <br></br>
              <Row>
                {this.state.cases.map((cases, index) => (
                  <Col key={index} xl={4} lg={4} md={4} sm={12} xs={24}>
                    <Row>
                      <Col span={2}></Col>
                      <Col span={20}><CaseItem key={index} grid clinicalcase={cases.data()} id={cases.id} seriesID={this.state.urlId} completed={this.state.readMap[cases.id] == true} /></Col>
                      <Col span={2}></Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CasesGrid;
