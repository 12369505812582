import React from 'react';
import {
	Button,
	Checkbox,
	Form,
	Icon,
	Input,
	message,
	Dropdown,
	DatePicker,
	Menu,
	Upload,
	Select,
	Col,
	Layout,
	Divider, Drawer,
	Row
} from 'antd';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import { auth, database } from '../firebase/firebase';
import HorizontalDefault from "./Topbar/HorizontalDefault/index";
import Moment from "moment";
import { version } from "util/config";
import AutoPlaySilentVideo from './AutoPlaySilentVideo';

import { connect } from 'react-redux';
import {
	userSignOut,
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignUp,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';


const FormItem = Form.Item;
const Option = Select.Option;

function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeUpload(file) {
	const isJPG = file.type === 'image/jpeg';
	if (!isJPG) {
		message.error('You can only upload JPG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJPG && isLt2M;
}

class SignUp extends React.Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			sex: 'Masculino',
			state: 'Aguascalientes',
			redirect: false,
			signing: false,
			values: {},
			loggedInVia: false,
			showCatFrame: false,
			showMGFrame: false,
			showInFrame: false,
			showGasFrame: false,
			origins: {},

			queryInsert: "",
			regSites: 0,
			//email: 'demo@example.com',
			//password: 'demo#123'
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.setRedirect = this.setRedirect.bind(this);
		//this.handleSignIn = this.handleSignIn.bind(this);

		this.handleSexClick = this.handleSexClick.bind(this);
		this.handleStateClick = this.handleStateClick.bind(this);
	}


	handleChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ loading: true });
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, (imageUrl) =>
				this.setState({
					imageUrl,
					loading: false
				})
			);
		}
	};
	handleSexClick(e) {
		//console.log(e);
		//console.log(e);
		this.setState({ sex: e });
	}
	handleStateClick(e) {
		this.setState({ state: e });
	}

	setRedirect = () => {
		this.setState({
			redirect: true
		});
	};
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to="/profesional-salud" />;
		}
	};
	isValidEmail(email) {
		if (email.includes("@") && email.includes(".")) {
			return true;
		} else {
			return false;
		}
		//return /\S+@\S+\.\S+/.test(email);
	}
	
	componentDidMount() {

		this.setState({ state: 'Aguascalientes', sex: 'Masculino', visit: 'SI', code: "NORUTEC001", isGoing: true });





		
	}

	register(err, values) {


		if (values.sex == "Masculino") {
			values.avatar = 'https://firebasestorage.googleapis.com/v0/b/gastrotourchinoin.appspot.com/o/Doctor.png?alt=media';

		} else {
			values.avatar = 'https://firebasestorage.googleapis.com/v0/b/gastrotourchinoin.appspot.com/o/Doctora.png?alt=media';
		}

		if (values.city === undefined) {
			values.city = ""
		}
		if (values.phone === undefined) {
			values.phone = ""
		}
		var _this = this;


		//if(this.state.isGoing == false){
		//if(values.age)
		this.setState({ sex: values.sex });
		this.setState({ code: values.code });
		this.setState({ city: values.code });
		this.setState({ phone: values.code });


		this.setState({ state: values.state });
		//console.log('CALLING LOADER');
		//this.props.showAuthLoader();

		this.setState({ values: values });
		var age = values.age
		values.age = age.format("DD-MM-YYYY")
		values.email = values.email.trim().toLowerCase()

		//console.log(values);
		database.collection('users').where('email', '==', values.email).get().then(function (snapshot) {
			if (snapshot.docs.length == 0) {
				_this.props.showAuthLoader();
				//console.log(Moment().format("DD/MM/YYYY'T'hh:mm:ss"))
				if (values.password.length >= 6) {
					values.registration_date = Moment().format("DD/MM/YYYY'T'hh:mm:ss")

					
					database
						.collection('users')
						.add(values)
						.then(function (docRef) {
							//console.log('WRITING USER DATA DONE');
							_this.props.userSignUp(values);
						})
						.catch(function (error) {
							//console.error('Error adding document: ', error);
						});
						
					
				} else {
					//console.log('Short Password');
					message.error('La contraseña debe de tener al menos 6 caracteres');

					//_this.props.alertMessage = 'Este usuario ya esta registrado';
				}
			} else {
				message.error('Este usuario ya esta registrado');

			}
		});

	}




	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			this.register(err, values)

		});
	};


	componentDidUpdate() {
		if (this.props.showMessage) {
			setTimeout(() => {
				this.props.hideMessage();
			}, 100);
		}
		if (!this.state.signing) {
			var refThis = this;
			var via = this.state.loggedInVia;
			if (this.props.authUser !== null) {
				auth.onAuthStateChanged((user) => {
					if (user) {
						
							console.log("innovacion via:" + via)
							console.log("innovacion waiting event...")

							refThis.props.history.push('/profesional-salud');
						


					
						//this.setRedirect();
					} else {

						if (refThis.state.values.email && refThis.state.values.password && refThis.state.signing == false) {
							
							refThis.setState({ signing: true });
							auth.signInWithEmailAndPassword(refThis.state.values.email, refThis.state.values.password)
								.then((authUser) => {
									//refThis.setRedirect();
									localStorage.setItem('user_email', refThis.state.values.email);
									localStorage.setItem('user_password', refThis.state.values.password);
									localStorage.setItem('user_id', authUser.user.uid);

									refThis.props.history.push('/profesional-salud');

								})
								.catch((error) => error);
						} else {
							//console.log('EMPTY VALUES');
						}
					}
				});
			} else {
				//console.log('AUTH USER IS NULL');
			}
		}
	}


	render() {
		const { getFieldDecorator } = this.props.form;
		const { showMessage, loader, alertMessage } = this.props;

		const imageUrl = this.state.imageUrl;
		return (
			<div >
				<img className="background-video" src={require('../assets/images/thumbnail.jpg')} alt="innovacion_chinoin" title="innovacion_chinoin" />

				<AutoPlaySilentVideo className="background-video" video={require("../assets/images/video_back.mp4")} videowebm={require("../assets/images/video_back.webm")} videoogg={require("../assets/images/video_back.ogg")} />

				<HorizontalDefault />

				<div class="menu-mobile">

<div>
	<Row span={24}>

		<Col span={1}></Col>
		<Col span={3}>
			<div onClick={() => this.setState({ visible: true })}><Icon type="menu" style={{ fontSize: '36px', color: '#fff' }} /> </div>
		</Col>
		<Col span={4}><a href="/"><img id="home-icon" src={require('../assets/images/home_icon.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></a></Col>
		<Col span={7}>
			<img src={require('../assets/images/logo-main-small.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
		</Col>
		<Col span={2}></Col>
		<Col span={6}>
			<img id="slight-top" src={require('../assets/images/norutec.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
		</Col>
		<Col span={1}></Col>

	</Row>

	<Drawer


		placement="right"
		closable={false}
		visible={this.state.visible}
	>
		<br></br>
		<Icon type="close" style={{ fontSize: '30px', color: '#fff', float: 'right' }} onClick={() => this.setState({ visible: false })} />
		<a href="/"><p class="drawer-item">ACERCA DE</p></a>
		<Divider />
		<a href="/profesional-salud"><p class="drawer-item">PROFESIONAL DE LA SALUD</p></a>
		<ul>
			<li><a href="/educacion-medica"><p class="drawer-subitem">EDUCACIÓN MÉDICA CONTINUA</p></a><Divider></Divider></li>

			<li><a href="/informacion-producto"><p class="drawer-subitem">INFORMACIÓN DE PRODUCTO</p></a><Divider></Divider></li>

			<li><a href="/experiencia-clinica"><p class="drawer-subitem">EXPERIENCIA CLÍNICA</p></a><Divider></Divider></li>
		</ul>
		<a href="/gastrobalance"><p class="drawer-item">GASTROBALANCE</p></a>
		<Divider />
		<a href="/#contacto"><p class="drawer-item">CONTACTO</p></a>
		<Divider />
	</Drawer>
</div>


</div>
				<div className="gx-app-login-wrap">


					<div className="gx-app-login-container">

						<div className="gx-app-login-main-content">

							<div className="gx-app-login-content">

								{/* <Row>
								<Col span="8" />
								<Col span="8">
									{' '}
								 <img alt="example" src={require('assets/images/asset_3.png')} /> 
								</Col>
								<Col span="8" />
							</Row>
							<br /> */}
								<Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
									<Row>
										<Col xl={1} lg={1} md={1} sm={0} xs={0}></Col>

										<Col xl={11} lg={11} md={11} sm={24} xs={24}>
											<FormItem>
												{getFieldDecorator('name', {
													rules: [{ required: true, message: 'Por favor escribe tu nombre' }]
												})(<Input placeholder="Nombre" />)}
											</FormItem>
											<FormItem>
												{getFieldDecorator('paternal_lastname', {
													rules: [{ required: true, message: 'Ingrese su apellido paterno' }]
												})(<Input placeholder="Apellido Paterno" />)}
											</FormItem>

											<FormItem>
												{getFieldDecorator('maternal_lastname', {
													rules: [{ required: true, message: 'Ingrese su apellido materno' }]
												})(<Input placeholder="Apellido Materno" />)}
											</FormItem>
											<FormItem>
												{getFieldDecorator('sex', {
													rules: [{ required: true, message: 'Por favor seleccione su sexo' }]
												})(
													<Select
														name="sex"
														onChange={this.handleSexClick}
														value={this.state.sex}
														placeholder="Sexo"
													>
														<Option value="Masculino">Masculino</Option>
														<Option value="Femenino">Femenino</Option>
													</Select>
												)}
											</FormItem>

											<FormItem>
												{getFieldDecorator('age', {
													rules: [
														{
															required: true,
															message: 'Por favor seleccione su fecha de nacimiento'
														}
													]
												})(
													<DatePicker
														className="gx-mb-3 gx-w-100"
														showTime={false}
														showToday={false}
														format="DD/MM/YYYY"
														placeholder="Fecha de nacimiento"
													/>
												)}
											</FormItem>

											<FormItem>
												{getFieldDecorator('state', {
													rules: [{ required: true, message: 'Por favor ingrese su estado' }]
												})(
													<Select
														name="state"
														onChange={this.handleStateClick}
														value={this.state.state}
														placeholder="Estado"
													>
														<Option value="Aguascalientes">Aguascalientes</Option>

														<Option value="Baja California">Baja California</Option>

														<Option value="Baja California Sur">Baja California Sur</Option>

														<Option value="Campeche">Campeche</Option>

														<Option value="Coahuila de Zaragoza">Coahuila</Option>

														<Option value="Colima">Colima</Option>

														<Option value="Chiapas">Chiapas</Option>

														<Option value="Chihuahua">Chihuahua</Option>

														<Option value="Ciudad de México">Ciudad de México</Option>

														<Option value="Durango">Durango</Option>

														<Option value="Guanajuato">Guanajuato</Option>

														<Option value="Guerrero">Guerrero</Option>

														<Option value="Hidalgo">Hidalgo</Option>

														<Option value="Jalisco">Jalisco</Option>

														<Option value="México">México</Option>

														<Option value="Michoacán">Michoacán</Option>

														<Option value="Morelos">Morelos</Option>

														<Option value="Nayarit">Nayarit</Option>

														<Option value="Nuevo León">Nuevo León</Option>

														<Option value="Oaxaca">Oaxaca</Option>

														<Option value="Puebla">Puebla</Option>

														<Option value="Querétaro">Querétaro</Option>

														<Option value="Quintana Roo">Quintana Roo</Option>

														<Option value="San Luis Potosí">San Luis Potosí</Option>

														<Option value="Sinaloa">Sinaloa</Option>

														<Option value="Sonora">Sonora</Option>

														<Option value="Tabasco">Tabasco</Option>

														<Option value="Tamaulipas">Tamaulipas</Option>

														<Option value="Tlaxcala">Tlaxcala</Option>

														<Option value="Veracruz">Veracruz</Option>

														<Option value="Yucatán">Yucatán</Option>

														<Option value="Zacatecas">Zacatecas</Option>
													</Select>
												)}
											</FormItem>
										</Col>
										<Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>

										<Col xl={10} lg={10} md={10} sm={24} xs={24} class="mobile-sigin-offset">
											<FormItem>
												{getFieldDecorator('city', {
													rules: [{ required: true, message: 'Por favor ingresa su ciudad' }]
												})(<Input placeholder="Ciudad" />)}
											</FormItem>
											<FormItem>
												{getFieldDecorator('license', {
													rules: [{ required: true, message: 'Ingrese su cédula profesional' }]
												})(<Input placeholder="Cédula profesional" />)}
											</FormItem>
											<FormItem>
												{getFieldDecorator('speciality', {
													rules: [{ required: true, message: 'Ingrese su especialidad' }]
												})(<Input placeholder="Especialidad" />)}
											</FormItem>
											<FormItem>
												{getFieldDecorator('phone', {
													rules: [{ required: false, message: 'Ingrese su teléfono' }]
												})(<Input placeholder="Teléfono" />)}
											</FormItem>

											{/* <span>Fotografía de perfil</span> */}

											{/* <FormItem>
									{getFieldDecorator('avatar', {
										rules: [
											{
												required: false,
												message: 'Please upload your avatar!'
											}
										]
									})(
										<Upload
											name="avatar"
											listType="picture-card"
											className="avatar-uploader"
											showUploadList={false}
											//action="//jsonplaceholder.typicode.com/posts/"
											beforeUpload={this.beforeUpload}
											onChange={this.handleChange}
										>
											{imageUrl ? <img src={imageUrl} alt="avatar" /> : uploadButton}
										</Upload>
									)}
								</FormItem> */}
											<FormItem>
												{getFieldDecorator('email', {
													rules: [
														{
															required: true,
															type: 'email',
															message: 'El correo electrónico no es válido'
														}
													]
												})(<Input placeholder="Email" />)}
											</FormItem>
											<FormItem>
												{getFieldDecorator('password', {
													rules: [{ required: true, message: 'Introduce tu contraseña' }]
												})(<Input type="password" placeholder="Password" />)}
											</FormItem>


										</Col>
									</Row>
									<FormItem>
										{getFieldDecorator('remember', {
											valuePropName: 'checked',
											initialValue: true
										})(
											<Checkbox>
												<IntlMessages id="appModule.iAccept" />
											</Checkbox>
										)}
										<span className="gx-link gx-signup-form-forgot">
											<a className="gx-login-form-forgot" href="https://www.chinoingastroinnovacion.com/aviso" target="_blank">	<IntlMessages id="appModule.termAndCondition" /></a>


										</span>
									</FormItem>
									<FormItem>
										<Button
											type="primary"
											className="gx-mb-0"
											htmlType="submit"
											onClick={this.handleSubmit}
										>
											<IntlMessages id="app.userAuth.signUp" />
										</Button>
										{' '}
										<Link to="/signin">
										<span class="colored">o Inicia sesión</span>
										</Link>
									</FormItem>
								</Form>
							</div>

							{loader ? (
								<div className="gx-loader-view">
									<CircularProgress />
								</div>
							) : null}
							{showMessage ? message.error(alertMessage.toString()) : null}

						</div>

						<span id="version-code">
							{version}</span>
						<br></br><br></br><br></br><br></br><br></br><br></br><br></br>
					</div>


				</div>
				<a href="https://www.chinoin.com/" target="_blank"><img id="corner-logo" src={require('../assets/images/esquina.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></a>

			</div>
		);
	}
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({ auth }) => {
	const { loader, alertMessage, showMessage, authUser } = auth;
	return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
	userSignUp,
	userSignOut,
	hideMessage,
	userSignIn,
	showAuthLoader,
	userFacebookSignIn,
	userGoogleSignIn,
	userGithubSignIn,
	userTwitterSignIn
})(WrappedSignUpForm);
