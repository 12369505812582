import React, { Component } from 'react';
import { Col, Row, Card } from 'antd';
import ProductItem from "components/eCommerce/ProductItem";
import { auth, database } from "../../../firebase/firebase";

class BookGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      urlId: 0,
      series: {},
      books: []
    };
    //this.handleChange=this.handleChange.bind(this);
  }

  getUserInformation(user) {
    //console.log("ALL USER INFO CALLED");
    var _this = this;
    database
      .collection("users")
      .where("email", "==", user.email)
      .get()
      .then(function(querySnapshot) {
        //console.log("ID: " + _this.state.urlId);
        querySnapshot.forEach(function(doc) {
          _this.setState({ user: doc.data() });
          doc.ref
            .collection("accesed_series")
            .where("series_id", "==", _this.state.urlId)
            .get()
            .then(function(snapshot) {
              var refArray = [];
              snapshot.forEach(function(series) {
                refArray.push(series.data());
              });
              //console.log(refArray);
              if (refArray.length == 0) {
                //console.log("NO ACCESS TO THIS BOOK");
              } else {
                database
                  .doc("series/" + _this.state.urlId)
                  .get()
                  .then(function(doc) {
                    if (doc.exists) {
                      var series = doc.data();
                      //console.log(series);
                      _this.setState({ series: series });
                        database
                          .collection("books")
                          .where("series","==",doc.id)
                          .get()
                          .then(function(snapshot) {
                            //console.log("BOOKS")
                            var arr = snapshot.docs.sort(function(a, b) {
                              if(a.data().title < b.data().title) { return -1; }
                              if(a.data().title > b.data().title) { return 1; }
                              return 0;
                            });
                            //console.log(snapshot.docs[0].data())
                              _this.setState({ books: arr });
                              
                            
                          });
                    } else {
                      //console.log("Series does not exist");
                    }
                  });
              }
            });
        });
      })
      .catch(function(error) {
        //console.log("Error gettin0g document:", error);
      });
  }

  componentDidMount() {
    const { urlId } = this.props.match.params;
    var _this = this;

    this.setState({ urlId: urlId });
    auth.onAuthStateChanged(user => {
      if (user) {
        _this.getUserInformation(user);
      }
    });
  }

  render() {
    return ( 
      <div>
				<Row>
					<Col key="ss" span={16}>
						<Card className={`gx-card-metrics`}>
							<h1 className="gx-product-title">{this.state.series.sponsor}</h1>
							<h4>{this.state.series.books_description}</h4>
							<p>{this.state.series.sponsor_message}</p>
							<p>{this.state.series.disclamer}</p>
						</Card>
					</Col>
					<Col key="1a" span={8}>
						<Card className={`gx-card-metrics`}>
							<img src={this.state.series.sponsor_logo} />
						</Card>
					</Col>
     
     
        {this.state.books.map((book, index) => (
          <Col key={index} xl={6} md={8} sm={12} xs={24}>
            <ProductItem key={index} grid product={book.data()} />
          </Col>
        ))}
      </Row>
      </div>
    );
  }
}

export default BookGrid;
