import React from "react";
import { Button } from "antd";
import StarRatingComponent from "react-star-rating-component";

import IntlMessages from "util/IntlMessages";

function CaseItem({ click, clinicalcase, grid, porcentage, id, seriesID, completed }) {
  //console.log(product)
  const { cover, subtitle, title, content } = clinicalcase;
  return (
    <div className={`gx-product-item  ${grid ? 'gx-product-vertical' : 'gx-product-horizontal'}`}>
      <div className="gx-product-image">
        <div className="gx-grid-thumb-equal">
          <span className="gx-link gx-grid-thumb-cover">
            <img src={cover} />
          </span>
        </div>
      </div>

      <div className="gx-product-body">
        <h3 className="gx-product-title">{title}
        </h3>
       
          <h3 style={{color:"#EF55A8"}}>
            {(completed) ? <div>  <div className="icon icon-check-circle-o">&nbsp;Completado</div></div> : null}
          </h3> 
      </div>


      {(content && content != "") ?
        <div className="gx-product-footer">
          <Button  type="primary"  href={'/series/' + seriesID + '/cases/'+ id} >Ver caso</Button></div> : null}



    </div>
  )
};

export default CaseItem;

